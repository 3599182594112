import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Rating from '@mui/lab/Rating';
import StarIcon from '@mui/icons-material/Star';
import { useActions } from '../../actions';
import { useSelector } from 'react-redux';
import { IRootState } from '../../reducers';
import { postFeedback } from '../../actions/feedback';
import { Text } from '../../common/Language';
import './style.css';
import { withStyles } from 'tss-react/mui';
import { createTheme, ThemeProvider } from '@mui/material';

const theme = createTheme({
  components: {
    // Name of the component
    MuiRating: {
      styleOverrides: {
        // Name of the slot
        label: {
          // Some CSS
          color: '#FFB539',
          fontSize: '2.2rem'
        },
        iconEmpty: {
          color: '#9e9e9e',
        },
      },
    },
  },
});

const Feedback = () => {
  const [open, setOpen] = React.useState(false);
  const [rating, setRating] = React.useState<number | null>(0);
  const [comment, setComment] = React.useState('');
  const postAssessmentFeedback = useActions(postFeedback);
  const feedback = useSelector((state: IRootState) => state.assessment.feedback);
  const assessmentData = useSelector(
    (state: IRootState) => state.assessment.assessmentSummary
  );
  function handleClose() {
    setOpen(false);
  }
  function handleSubmit() {
    setOpen(false);
    if (feedback.status !== 'success') {
      postAssessmentFeedback(
        {
          rating,
          comment,
        },
        assessmentData.data!.assessmentId
      );
    }
  }

  useEffect(() => {
    const timerRef = setTimeout(() => {
      setOpen(true);
    }, 3000);

    return () => clearTimeout(timerRef);
  }, []);

  function handleRatingClick(event: object, value: number | null) {
    // console.log('Rating value --', value);
    setRating(value);
  }

  /* function handleRatingClick(value: number) {
         console.log('Rating value --', value);
         setRating(value);
     }*/

  function handleCommentChange(event: any) {
    // console.log('comment click--', event.target.value);
    setComment(event.target.value);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle id='form-dialog-title'>
        <Text tid='rateYourExperienceWithYourAssessment' />
      </DialogTitle>
      <DialogContent>
        <ThemeProvider theme={theme}>
          <Rating
            name='size-large'
            value={rating}
            precision={1}
            onChange={handleRatingClick}
            getLabelText={()=>"hhj"}
            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
/>
        </ThemeProvider>
        <TextField
          id='standard-textarea'
          label='Please provide your comments here'
          multiline
          fullWidth
          margin='normal'
          onChange={handleCommentChange}
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          <Text tid='cancel' />
        </Button>
        <Button onClick={handleSubmit} color='primary'>
          <Text tid='submit' />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default Feedback;
