import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Container,
  Theme,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TableSortLabel,
  TablePagination,
  IconButton,
  useTheme,
  Link,
  Modal,
  Grid,
  Divider,
  CircularProgress,
} from '@mui/material';
import { Alert, AlertTitle } from '@mui/lab';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { convertSecondsToTime, getDateTime } from '../../../../utils/data';
import { Http } from '../../../../utils';
import { IRootState } from '../../../../reducers';
import { makeStyles, withStyles } from 'tss-react/mui';

import CloseIcon from '@mui/icons-material/Close';
import {
  IBuildsListDataItem,
  STATUS_FAILED,
  //  STATUS_OTHER,
  STATUS_SUCCESS,
  STATUS_INPROGRESS,
  BuildDatabaseStageDataItem,
} from '../../../../model/metrics/buildsData';
import Loader from '../../../loader';
import { ALL } from '../../../../pages/metrics/metric-select';
import { Text } from '../../../../common/Language';
import '../../../../css/metrics/style.css';
import StageBox from './StageBox';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { fetchBuildData } from './fetchBuildData';
import { fetchJobLogs } from '../../../../pages/viewPipelines/fetchJobLogs';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: any[], comparator: (a: any, b: any) => number) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof IBuildsListDataItem;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'buildNum', numeric: false, disablePadding: true, label: 'buildId' },
  { id: 'teamId', numeric: true, disablePadding: false, label: 'team' },
  { id: 'service', numeric: false, disablePadding: false, label: 'service' },
  { id: 'projectName', numeric: true, disablePadding: false, label: 'project' },
  { id: 'status', numeric: true, disablePadding: false, label: 'status' },
  { id: 'startTimestamp', numeric: true, disablePadding: false, label: 'Start Date' },
  { id: 'endTimestamp', numeric: true, disablePadding: false, label: 'End Date' },
  { id: 'duration', numeric: true, disablePadding: false, label: 'duration' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof IBuildsListDataItem
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof IBuildsListDataItem) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='center'
            sortDirection={orderBy === headCell.id ? order : false}
            className='tableHeadMetrics'
          >
            <TableSortLabel
              hideSortIcon={true}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <label className='tableHeadMetrics'>
                <Text tid={headCell.label} />
              </label>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles()((theme: Theme) =>
  ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      boxShadow: '0px 0px 1px #888888',
    },
    table: {
      minWidth: 350,
    },
    modal: {
      backgroundColor: '#fff',
      marginTop: '35px',
    },
    container: {
      maxHeight: 200,
    },
    paperModal: {
      width: '80vw',
      //height: '30vh',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(4),
      display: 'relative',
      borderRadius: '10px'
    },
    modalContainer: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '0px',
    },
    stageBox: {
      border: '1px solid #ccc!important',
      borderRadius: '16px',
      display: 'flex',
      margin: '10px',
      padding: '10px'
    },
    stagetext: {
      marginLeft: '8px'
    },
    noPipelineData: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center'
    }
  })
);

const useStyles1 = makeStyles()((theme: Theme) => (
  {
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  })
);

const useStyles2 = makeStyles()((theme: Theme) => ({
  toolbar: {
    fontSize: '12px !important',
    fontWeight: 'bold',
    display: 'initial',
  },
  paper: {
      marginTop: '1rem',
      //maxHeight: '30px',
      overflowX: 'auto',
      borderRadius: '5px',
      padding: '10px'
  },
  paperTop: {
      paddingTop: '10px',
      paddingBottom: '10px'
  },
  container: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(3),
      position: 'relative',
      top: '120px',
  },
  logContainer: {
    minWidth: "100%",
    maxWidth: "100%",
    padding: '5px 5px 0px 5px',
    marginTop: '40px'
  },
  logBox: {
    border: '2px solid #000',
    borderRadius: '4px',
    display: 'block'
  },
  logBoxHidden: {
    display: 'none'
  },
  modalContent: {
    maxHeight: '50vh',
    overflow: 'scroll'
  },
  stagesContainer: {display: 'flex', overflow: 'auto', backgroundColor: '#fafafa', minHeight: '10vh', padding: '20px'},
  stageboxArrow: {display: 'flex'},
  arrowIcon: {margin: '27px 5px 0px 5px'},
  hidden: {display: 'none'},
  displayed: {display: 'block', minWidth: '100%', position: 'relative'},
}));


interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const { classes } = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
        size="large">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
        size="large">
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
        size="large">
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
        size="large">
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const BuildTable = (props: any) => {
  const { classes } = useStyles();
  const styles = useStyles();
  const styles2 = useStyles2();
  const classes2 = styles2.classes;
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IBuildsListDataItem>('buildNum');
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [passedBuild, setPassedBuild] = useState(true);
  const [failedBuild, setFailedBuild] = useState(true);
  const [rollbackBuild, setRollbackBuild] = useState(true);
  const [otherBuild, setOtherBuild] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [buildsData, setBuildsData] = useState<IBuildsListDataItem[]>([]);
  const [displayData, setDisplayData] = useState<IBuildsListDataItem[]>([]);
  const stateVariable = useSelector((state: IRootState) => {
    return state;
  });
  const [failureMsg, setFailureMsg] = useState(false);
  const [loader, setLoader] = useState(true);
  const [loadingTimeline, setLoadingTimeline] = useState(true);
  const history = useHistory();
  const [showPipeline, setShowPipeline] = useState(false);
  const [focusPipelineData, setFocusPipelineData] = useState<IBuildsListDataItem | null>();

  /* Pipeline job related states */
  const [gitlabToken, setGitlabToken] = useState('');
  const [stageLogParams, setStageLogParams] = useState<any>({});
  const [stageLogs, setStageLogs] = useState('');
  const [showModalLoader, setShowModalLoader] = useState(false);
  const [showLogs, setShowLogs] = useState(false);

  useEffect(() => {
    setLoadingTimeline(true);
    setDisplayData([]);
    fetchData();
  }, [props.focusTeam, props.focusService, props.focusSubService, props.focusServiceType, props.selectedDateRange]);

  useEffect(() => {
    let { buildStatus } = props;
    if ((buildStatus.status & 1) === 1) {
      setPassedBuild(!passedBuild);
    }
    if ((buildStatus.status & 2) === 2) {
      setFailedBuild(!failedBuild);
    }
    if ((buildStatus.status & 4) === 4) {
      setRollbackBuild(!rollbackBuild);
    }
    //    if ((props.buildStatus.status & 8) === 8) {
    //      setOtherBuild(!otherBuild);
    //    }
  }, [props.buildStatus]);

  useEffect(() => {
    let temp: IBuildsListDataItem[] = [];
    buildsData.map((data: IBuildsListDataItem) => {
      if ((passedBuild && data.status === STATUS_SUCCESS)
        || (failedBuild && data.status === STATUS_FAILED)
        || (rollbackBuild && data.status === STATUS_INPROGRESS)
        //        || (otherBuild && data.status === STATUS_OTHER)
      ) {
        temp.push(data);
      }
    });
    setDisplayData(temp);
  }, [passedBuild, failedBuild, rollbackBuild, otherBuild]);

  const fetchData = async() => {
    const response: any = await fetchBuildData(props, stateVariable).catch((error) => {
      console.error(error)
      setLoader(false);
      setLoadingTimeline(false);
      setFailureMsg(true);
      const perror = JSON.stringify(error);
      const object = JSON.parse(perror);
      if (object.code === 401) {
        history.push('/relogin')
      } else {
        history.push('/error')
      }
    });
    if (response) {
      if (response.chartData) {
        setBuildsData(response.chartData);
        setLoader(false);
        setLoadingTimeline(false);
        setPassedBuild(true);
        setFailedBuild(true);
        setOtherBuild(true);
        setDisplayData(response.chartData);
      }
      if(response.token) setGitlabToken(response.token)
    } else {
      setLoader(false);
      setLoadingTimeline(false);
      setFailureMsg(true);
    }
  }

  const StyledTableRow = withStyles(TableRow, (theme: Theme) =>
    ({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: '#f1f1f1',
        },
      },
    })
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IBuildsListDataItem
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = displayData.map((n: any) => n.buildNum);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, displayData.length - page * rowsPerPage);

  const showPipleine = (row: IBuildsListDataItem) => {
    setShowPipeline(true);
    setFocusPipelineData(row);
  }

  const handleClose = () => {
    setShowPipeline(false);
    setFocusPipelineData(null);
    setShowLogs(false);
    setStageLogParams({});
  }

  const fetchJobLevelLogs = async() => {
    const response = await fetchJobLogs(gitlabToken, stageLogParams);
    if(response ) {
        setShowModalLoader(false);
        setStageLogs(response);
    } else {
        setShowModalLoader(false);
        setStageLogs("We are unable to fetch the logs at this moment. Please try later.");
    }
  }

  useEffect(() => {
    if(showLogs && stageLogParams.stageId && stageLogParams.projectId) {
        fetchJobLevelLogs()
    }
  }, [stageLogParams, showLogs])

  const onStageClick = (stageId: string, projectId: string) => {
    setShowLogs(true);
    setShowModalLoader(true)
    setStageLogParams({stageId, projectId});
  }

  const handleCloseLogs = () => {
    setShowLogs(false);
    setStageLogs('');
    setStageLogParams({});
}

  const renderStages = (focusPipelineData: IBuildsListDataItem) => {
    const stages = focusPipelineData.stages.slice(0);
    return (
      <div className={classes2.stagesContainer}>
        {stages.slice(0).map((stage, i) => {
          if(i === focusPipelineData.stages.length - 1) {
              return <StageBox key={stage.stageId} stage={stage} onClick={onStageClick} projectId={focusPipelineData.projectId}
                isSelected={showLogs && stageLogParams.stageId === stage.stageId}
                provideAction={false} takeAction={()=>{}}/>
          }
          return (
              <div key={stage.stageId} className={classes2.stageboxArrow}>
                  <StageBox stage={stage} onClick={onStageClick} projectId={focusPipelineData.projectId}
                    isSelected={showLogs && stageLogParams.stageId === stage.stageId}
                    provideAction={false} takeAction={()=>{}}/>
                  <div className={classes2.arrowIcon}>
                    <ArrowForwardIcon />
                  </div>
              </div>
          )
        })}
      </div>
    )
  }

  const renderTable = () => {
    return (
      <Paper className={classes.paper}>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            aria-label='sticky table'
            className={classes.table}
            aria-labelledby='tableTitle'
            size='small'
          >
            <EnhancedTableHead
              classes={styles}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={Number(buildsData)}
            />
            <TableBody style={{ overflow: 'auto' }}>
              {displayData.length ? (
                stableSort(displayData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: IBuildsListDataItem, index: number) => {
                    return (
                      <StyledTableRow key={index}>
                        <TableCell align='center'>
                          <a
                            href={row.url}
                            target='_blank'
                            rel='noopener noreferrer'
                            style={{ textDecoration: 'underline' }}
                          >
                            {row.buildNum}
                          </a>
                        </TableCell>
                        <TableCell align='center'>{row.teamId}</TableCell>
                        <TableCell align='center'>{row.service}</TableCell>
                        <TableCell align='center'>{row.projectName}</TableCell>
                        <TableCell align='center'>
                          <Link onClick={() => {
                            showPipleine(row);
                          }} style={{cursor: 'pointer', color: '#039be5', textDecoration: 'underline'}}>
                            {row.status}
                          </Link>
                        </TableCell>
                        <TableCell align='center'>
                          {row.startTimestamp ? getDateTime(row.startTimestamp) : '-'}
                        </TableCell>
                        <TableCell align='center'>
                          {row.endTimestamp ? getDateTime(row.endTimestamp) : '-'}
                        </TableCell>
                        <TableCell align='center'>
                          {convertSecondsToTime(row.duration)}
                        </TableCell>
                      </StyledTableRow>
                    );
                  })
              ) : (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} align='center'>
                    {loadingTimeline ? 'Loading...' : 'No Records Found'}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage='Records per page'
          rowsPerPageOptions={[5, 10, 20, 50]}
          component='div'
          count={displayData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelDisplayedRows={({ to, count }) => `${to} of ${count}`}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    );
  };

  return (
    <div className={classes.root}>
      <Typography variant='subtitle2'>
        <Box
          fontWeight={700}
          className='subTitleMetricStyle'
          mb={props.loader || props.failureMsg ? 2 : 1.5}
        >
          <Text tid='buildsDetails' />
        </Box>
      </Typography>
      {loader ? (
        <Container className='loaderStyle'>
          <Loader />
        </Container>
      ) : failureMsg ? (
        <Alert severity='error'>
          <AlertTitle>
            <Text tid='error' />
          </AlertTitle>
          <Text tid='somethingWentWrong' />
        </Alert>
      ) : (
        renderTable()
      )}
      <Modal
        open={showPipeline}
        onClose={handleClose}
        aria-labelledby="pipeline-show-modal"
        aria-describedby="shows-pipeline-graphical-data"
      >
        <div className={classes.modalContainer}>
        <div className={classes.paperModal}>
        <div style={{width: '100%', alignItems: 'right', position: 'relative'}}>
          <CloseIcon style={{position: 'absolute', right: '-15px', top: '-15px', cursor: 'pointer'}} onClick={handleClose}/>
        </div>
        <div>
        {
          focusPipelineData && Object.keys(focusPipelineData) && Object.keys(focusPipelineData).length ?
            <div>
              <Grid container className={classes2.paperTop}>
                    <Grid item md={3}>
                        <div style={{display: 'flex', marginTop: '5px'}}>

                        <Typography style={{fontWeight: 600}}>Build Number:&nbsp;</Typography>
                        <Typography>
                            <a
                                href={focusPipelineData.url}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{ textDecoration: 'underline' }}
                            >
                                {focusPipelineData.buildNum}
                            </a>
                        </Typography>
                        </div>
                        <div style={{display: 'flex', marginTop: '5px'}}>
                        <Typography style={{fontWeight: 600}}><Text tid="status" />:&nbsp;</Typography>
                        <Typography>{focusPipelineData.status}</Typography>
                        </div>
                    </Grid>
                    <Grid item md={3} style={{display: 'flex'}}>
                        <Typography style={{fontWeight: 600}}>
                            <Text tid="team"/>:&nbsp;
                        </Typography>
                        <Typography>{focusPipelineData.teamId}</Typography>
                    </Grid>
                    <Grid item md={4}>
                        <div style={{display: 'flex'}}>
                        <Typography style={{fontWeight: 600}}>
                            <Text tid="startDate"/>&nbsp;</Typography>
                        <Typography>{focusPipelineData.startTimestamp ? getDateTime(focusPipelineData.startTimestamp) : '-'}</Typography>
                        </div>
                        <div style={{display: 'flex', marginTop: '5px'}}>
                        <Typography style={{fontWeight: 600}}> <Text tid="endDate"/>&nbsp;</Typography>
                        <Typography>{focusPipelineData.endTimestamp ? getDateTime(focusPipelineData.endTimestamp) : '-'}</Typography>
                        </div>
                    </Grid>
                    <Grid item md={2}>
                        <div style={{display: 'flex'}}>
                        <Typography style={{fontWeight: 600}}><Text tid="durationWord" />:&nbsp;</Typography>
                        <Typography>{convertSecondsToTime(focusPipelineData.duration)}</Typography>
                        </div>
                        <div style={{display: 'flex'}}>
                        <Typography style={{fontWeight: 600}}><Text tid="jobDuration" />:&nbsp;</Typography>
                        <Typography>{convertSecondsToTime(focusPipelineData.jobDuration)}</Typography>
                        </div>
                        <Typography>{`(Total wait time: ${convertSecondsToTime(focusPipelineData.pauseDuration)})`}</Typography>
                    </Grid>
                </Grid>
              <Divider/>
              <div>
              {focusPipelineData.stages.length ?
                  <div>
                  {renderStages(focusPipelineData)}
                  <div className={!showLogs? classes2.hidden : classes2.displayed}>
                    <div style={{position: 'absolute', top: '10px', right: '0px', cursor: 'pointer'}}>
                    {
                      showModalLoader? 
                      <div>
                        <CircularProgress/>
                      </div> : <div onClick={handleCloseLogs}>
                        <CloseIcon/>
                      </div>
                    }
                    </div>
                  </div>
                  <div className={classes2.logContainer}>
                    <div className={showLogs && !showModalLoader? classes2.logBox : classes2.logBoxHidden}>
                      <div className={classes2.modalContent}><code style={{whiteSpace: "pre-wrap"}}>{stageLogs}</code></div>
                    </div>
                  </div>
                  </div> : <div style={{textAlign: 'center', padding: '30px'}}>
                      There are no stages on this pipeline.
                  </div>
              }
              </div>
          </div> : 
          <div style={{textAlign: 'center', padding: '30px'}}>
              There are no stages on this pipeline.
          </div>
        }
      </div>
      </div>
      </div>
        
      </Modal>
    </div>
  );
};

export default withRouter(BuildTable);
