import React, { useEffect, useState } from 'react';
import {
  Container,
  Theme,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TableSortLabel,
  TablePagination,
  IconButton,
  useTheme,
  Grid,
  Link,
  Tooltip,
  ThemeProvider,
} from '@mui/material';
import { Alert, AlertTitle } from '@mui/lab';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Loader from '../../loader';
import { Http } from '../../../utils';
import { IRootState } from '../../../reducers';
import { IIncidentListDataItem, INCIDENT_STATUS_CLOSED, INCIDENT_STATUS_OPEN, INCIDENT_STATUS_RESOLVED,  } from '../../../model/metrics/incident';
import { ALL } from '../../../pages/metrics/metric-select';
import { downloadCSV, getDateTime, getDaysfromHours, getJiraId, getTimeDifferenceInHrs, objectToCsvRequirements } from '../../../utils/data';
import { Text } from '../../../common/Language';
import '../../../css/metrics/style.css';
import { CommonTooltip } from '../common/infoTooltip';
import { tooltipTheme } from '../../../common/common';
import { makeStyles, withStyles } from 'tss-react/mui';


declare module '@mui/styles/defaultTheme' {
  // tslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


function descendingComparator(a: any, b: any, orderBy: keyof any) {

  if (orderBy === 'leadTime' || orderBy === 'cycleTime') {
    if(!a['closedOn']) {
      return 1;
    }
    if(!b['closedOn']) {
      return -1;
    }
    let beginDate = 'createdOn';
    if(orderBy === 'cycleTime') {
      beginDate = 'startedOn';
    }
    let a_val = a['closedOn'] - a[beginDate];
    let b_val = b['closedOn'] - b[beginDate];
    if (b_val < a_val) {
      return -1;
    }
    if (b_val > a_val) {
      return 1;
    }
    return 0;
  }
  if(orderBy === 'itemId') {
    if(!a[orderBy]) {
      return 1;
    }
    if(!b[orderBy]) {
      return -1;
    }
    const a_val = getJiraId(a.url);
    const b_val = getJiraId(b.url);
    if (b_val < a_val) {
      return -1;
    }
    if (b_val > a_val) {
      return 1;
    }
  }
  if(!a[orderBy]) {
    return 1;
  }
  if(!b[orderBy]) {
    return -1;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator(
  order: Order,
  orderBy: keyof IIncidentListDataItem
) {
  return order === 'desc'
    ? (a: IIncidentListDataItem, b: IIncidentListDataItem) => descendingComparator(a, b, orderBy)
    : (a: IIncidentListDataItem, b: IIncidentListDataItem) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: any[], comparator: (a: any, b: any) => number) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof IIncidentListDataItem;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'itemId', numeric: false, disablePadding: true, label: 'incidentId' },
  { id: 'itemPriority', numeric: false, disablePadding: true, label: 'itemPriority' },
  { id: 'teamId', numeric: false, disablePadding: false, label: 'team' },
  // For Opsgenie Service is not the Opsgenie service but the service belonging to the team in DoItRight
  { id: 'service', numeric: false, disablePadding: false, label: 'service' },
  { id: 'projectName', numeric: false, disablePadding: false, label: 'project' },
  { id: 'startTime', numeric: true, disablePadding: false, label: 'createdOnUTC' },
  { id: 'endTime', numeric: true, disablePadding: false, label: 'closedOnUTC' },
  { id: 'status', numeric: false, disablePadding: false, label: 'status' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof IIncidentListDataItem
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof IIncidentListDataItem) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='center'
            sortDirection={orderBy === headCell.id ? order : false}
            className='tableHeadMetrics'
            rowSpan={1}
          >
            <TableSortLabel
              hideSortIcon={true}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <label className='tableHeadMetrics'>
                <Text tid={headCell.label} />
              </label>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles()((theme: Theme) =>
  ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(1),
      boxShadow: '0px 0px 1px #888888',
    },
    table: {
      minWidth: 300,
    },
    container: {
      maxHeight: 208,
    },
  })
);

const useStyles1 = makeStyles()((theme: Theme) =>
  ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  })
);

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const { classes } = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
        size="large">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
        size="large">
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
        size="large">
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
        size="large">
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

export default function IncidentTable(props: any) {
  const { classes } = useStyles();
  const styles = useStyles();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IIncidentListDataItem>('itemId');
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const stateVariable = useSelector((state: IRootState) => {
    return state;
  });
  const [incidentListData, setIncidentListData] = useState<IIncidentListDataItem[]>([]);
  const [filteredIncidentListData, setFilteredIncidentListData] = useState<IIncidentListDataItem[]>([]);
  const [failureMsg, setFailureMsg] = useState(false);
  const [loader, setLoader] = useState(true);
  const [loadingTimeline, setLoadingTimeline] = useState(true);
  const history = useHistory();
  const {focusIncidentStatus} = props;

  const StyledTableRow = withStyles(TableRow, (theme: Theme) =>
    ({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: '#f1f1f1',
        },
      },
    })
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IIncidentListDataItem
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    setLoadingTimeline(true);
    setIncidentListData([]);
    setFilteredIncidentListData([])
    fetchData();
  }, [
    props.focusTeam,
    props.focusService,
    props.focusSubService,
    props.focusServiceType,
    props.incidentStatus,
    props.incidentPriority,
    props.selectedDateRange,
  ]);

  useEffect(() => {
    const filteredData = incidentListData.filter((el) => {
      const mapp: any = {
        'Closed': INCIDENT_STATUS_CLOSED,
        'Resolved': INCIDENT_STATUS_RESOLVED,
        'Open': INCIDENT_STATUS_OPEN 
      }
      if(focusIncidentStatus.indexOf(mapp[el.status]) === -1) {
        return false;
      }
      return true
    })
    setFilteredIncidentListData(filteredData);
  }, [focusIncidentStatus])

  function getUniqueListBy(arr: any, key: string) {
    const newmap: any = new Map(arr.map((item: any) => [item[key], item]));
    return [...newmap.values()];
  }

  const fetchData = async() => {
    let { timeline, focusTeam, focusService, focusSubService, focusServiceType, joinServiceAndSubService, incidentStatus, incidentPriority, selectedDateRange } =
      props;
    let url: string = '/api/metrics/incidents/list';
    let joiner = '?';
    console.log("Table props: ", props);
    if (focusTeam[0] !== ALL) {
      url = `${url}${joiner}teamId=${focusTeam.toString()}`;
      joiner = '&';
    }
    if (focusService[0] !== ALL) {
      url = `${url}${joiner}service=${focusService.join()}`;
      joiner = '&';
    }
    if (focusSubService[0] !== ALL) {
      url = `${url}${joiner}subService=${focusSubService.join()}`;
      joiner = '&';
    }
    if (focusServiceType[0] !== ALL) {
      url = `${url}${joiner}serviceType=${focusServiceType.join()}`;
      joiner = '&';
    }
    if (incidentStatus[0] !== ALL) {
      url = `${url}${joiner}type=${incidentStatus.toString()}`;
      joiner = '&';
    }
    if (incidentPriority[0] !== ALL) {
      url = `${url}${joiner}priority=${incidentPriority.toString()}`;
      joiner = '&';
    }

    const toDateInt = parseInt(selectedDateRange.toDate, 10);
    const fromDateInt = parseInt(selectedDateRange.fromDate, 10);
    const sixMonthsInMilli = 6 * 31 * 24 * 60 * 60 * 1000;
    if((toDateInt - fromDateInt) > sixMonthsInMilli) {
      const interval = 121 * 24 * 60 * 60 * 1000;
      let lastFetchedDateValue = fromDateInt;
      let response : any = [];
      for(let i = 1; i <= 3; i += 1) {
        const fetchUrl = i === 3 ? `${url}${joiner}fromDate=${lastFetchedDateValue}&toDate=${toDateInt}` :
        `${url}${joiner}fromDate=${lastFetchedDateValue}&toDate=${fromDateInt + i * interval}`;
        const responseFrag : any = await Http.get({
          url: fetchUrl,
          state: stateVariable,
        }).catch((error) => {
          setLoader(false);
          setLoadingTimeline(false);
          setFailureMsg(true);
          const perror = JSON.stringify(error);
          const object = JSON.parse(perror);
          if (object.code === 401) {
            history.push('/relogin')
          }
        });
        if(responseFrag && responseFrag.length > 0) {
          response = response.concat(responseFrag);
        }
        lastFetchedDateValue = fromDateInt + i * interval;
      }
      if (response) {
        response = getUniqueListBy(response, 'itemId');
        setIncidentListData((oldData) => {
          if(oldData.length === 0) {
            return response;
          }
          if(response.length === 0 && incidentPriority[0] === ALL && incidentStatus[0] === ALL && focusServiceType[0] === ALL
            && focusTeam[0] === ALL && focusService[0] === ALL && focusSubService[0] === ALL) {
            console.warn("Tables Faulty url:", url);
            return;
          }
          return response
        });
        //setIncidentListData(response);
        setFilteredIncidentListData(response);
        setLoader(false);
        setLoadingTimeline(false);
      } else {
        setLoader(false);
        setLoadingTimeline(false);
        setFailureMsg(true);
      }
    } else {
      url = `${url}${joiner}fromDate=${selectedDateRange.fromDate}&toDate=${selectedDateRange.toDate}`;
      joiner = '&';
      const response : any = await Http.get({
        url,
        state: stateVariable,
      }).catch((error) => {
        setLoader(false);
        setLoadingTimeline(false);
        setFailureMsg(true);
        const perror = JSON.stringify(error);
        const object = JSON.parse(perror);
        if (object.code === 401) {
          history.push('/relogin')
        }
      });
      if (response) {
        setIncidentListData(response);
        setFilteredIncidentListData(response);
        setLoader(false);
        setLoadingTimeline(false);
      } else {
        setLoader(false);
        setLoadingTimeline(false);
        setFailureMsg(true);
      }
      }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = filteredIncidentListData.map((n: any) => n.buildNum);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredIncidentListData.length - page * rowsPerPage);

  const renderTable = () => {
    return (
      <Paper className={classes.paper}>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            aria-label='sticky table'
            className={classes.table}
            aria-labelledby='tableTitle'
            size='small'
            // aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={styles}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={Number(filteredIncidentListData)}
            />
            <TableBody style={{ overflow: 'auto', paddingTop: '10px' }}>
              {filteredIncidentListData.length ? (
                stableSort(filteredIncidentListData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: IIncidentListDataItem, index) => {
                    const id = getJiraId(row.url);
                    
                    return (
                      <StyledTableRow key={index}>
                        <TableCell align='center'>
                          <a
                            href={row.url}
                            target='_blank'
                            rel='noopener noreferrer'
                            style={{ textDecoration: 'underline' }}
                          >
                            {id}
                          </a>
                        </TableCell>
                        <TableCell align='center'>{row.itemPriority}</TableCell>
                        <TableCell align='center'>{row.teamId}</TableCell>
                        <TableCell align='center'>{row.service}</TableCell>
                        <TableCell align='center'>{row.projectName}</TableCell>
                        <TableCell align='center'>
                          {row.startTime ? getDateTime(row.startTime * 1000) : '-'}
                        </TableCell>
                        <TableCell align='center'>
                          {row.endTime
                            ? getDateTime(row.endTime * 1000)
                            : '-'}
                        </TableCell>
                        <TableCell align='center'>{row.status}</TableCell>
                      </StyledTableRow>
                    );
                  })
              ) : (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={11} align='center'>
                    {loadingTimeline ? 'Loading...' : 'No Records Found'}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage='Records per page'
          rowsPerPageOptions={[5, 10, 20, 50]}
          component='div'
          count={filteredIncidentListData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelDisplayedRows={({ to, count }) => `${to} of ${count}`}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    );
  };

  /* const downloadExcel = async() => {
    const csvData = await objectToCsvRequirements(filteredIncidentListData);
    downloadCSV(csvData, "LT_CT_Details.csv");
  } */

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item sm={6}>
          <Box fontWeight={700} mb={props.loader || props.failureMsg ? 2 : 1.5}>
            <Typography variant='subtitle2' style={{fontWeight: 700, display: 'inline-block'}} className='subTitleMetricStyle'>
              <Text tid='details' />
            </Typography>
            <CommonTooltip text='detailsTableTooltip'/>
          </Box>
        </Grid>
        {/* <Grid item sm={6}>
          <div style={{float: 'right'}}>
            <Link style={{ cursor: 'pointer' }} onClick={downloadExcel}>
              <Text tid="downloadCSV"/>
            </Link>
          </div>
        </Grid> */}
      </Grid>
      {loader ? (
        <Container className='loaderStyle'>
          <Loader />
        </Container>
      ) : failureMsg ? (
        <Alert severity='error'>
          <AlertTitle>
            <Text tid='error' />
          </AlertTitle>
          <Text tid='somethingWentWrong' />
        </Alert>
      ) : (
        renderTable()
      )}
    </div>
  );
}
