import { TIME_ONE_DAY } from "../../../../common/common";
import { ALL } from "../../../../pages/metrics/metric-select";
import { IRootState } from "../../../../reducers";
import { Http } from "../../../../utils";

export const fetchBuildData = async(props: any, stateVariable: IRootState) => {
  return new Promise(async(resolve, reject) => {
    let { timeline, focusTeam, focusService, focusSubService, focusPipelineStatus, joinServiceAndSubService, selectedDateRange } = props;
    let url: string = '/api/metrics/builds/list';
    let joiner = '?';
    if (focusTeam[0] !== ALL) {
      url = `${url}${joiner}teamId=${focusTeam.toString()}`;
      joiner = '&';
    }
    /* if (focusService[0] !== ALL && focusSubService[0] !== ALL) {
      url = `${url}${joiner}service=${joinServiceAndSubService()}`;
      joiner = '&';
    } else */
    if (focusService[0] !== ALL) {
      url = `${url}${joiner}service=${focusService.join()}`;
      joiner = '&';
    }
    if (focusSubService[0] !== ALL) {
      url = `${url}${joiner}subService=${focusSubService.join()}`;
      joiner = '&';
    }
    if(focusPipelineStatus && focusPipelineStatus[0] !== ALL) {
      url = `${url}${joiner}status=${focusPipelineStatus.toString()}`;
      joiner = '&';
    }

    const toDateInt = parseInt(selectedDateRange.toDate, 10);
    const fromDateInt = parseInt(selectedDateRange.fromDate, 10);
    const oneMonthInMilli = 30 * 24 * 60 * 60 * 1000;
    if((toDateInt - fromDateInt) > oneMonthInMilli) {
      const interval = 30 * 24 * 60 * 60 * 1000;
      let lastFetchedDateValue = fromDateInt;
      let response : any = {
        chartData: [],
        token: ""
      };
      const interationCount = Math.round((toDateInt - fromDateInt) / oneMonthInMilli) + 1;
      for(let i = 1; i <= interationCount; i += 1) {
        if(lastFetchedDateValue >= toDateInt) {
          continue;
        }
        const toDateNew = (fromDateInt + i * interval);
        const fetchUrl = toDateNew > toDateInt ? `${url}${joiner}fromDate=${lastFetchedDateValue}&toDate=${toDateInt}` :
        `${url}${joiner}fromDate=${lastFetchedDateValue}&toDate=${toDateNew}`;
        const responseFrag : any = await Http.get({
          url: fetchUrl,
          state: stateVariable,
        }).catch((error) => {
          return reject(error);
        });
        if(responseFrag && responseFrag.chartData && responseFrag.chartData.length > 0) {
          response.chartData = response.chartData.concat(responseFrag.chartData);
        }
        if(responseFrag && responseFrag.token) {
          response.token = responseFrag.token;
        }
        lastFetchedDateValue = fromDateInt + i * interval;
      }
      if (response) {
        return resolve(response);
      } else {
        return reject(new Error('Blank response'));
      }
    } else {
      if (timeline !== TIME_ONE_DAY) {
        url = `${url}${joiner}fromDate=${selectedDateRange.fromDate}&toDate=${selectedDateRange.toDate}`;
        joiner = '&';
      }
      const response : any = await Http.get({
        url,
        state: stateVariable,
      }).catch((error) => {
        return reject(error);
      });
      return resolve(response);
    }
  })
    
   /*  return Http.get({
      url,
      state: stateVariable
    }); */
  };