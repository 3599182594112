import { IAssessmentListItem, ITeamProcessedData } from "../../model";

export function compareReverseCronological(a: ITeamProcessedData, b: ITeamProcessedData) {
    const aDate = a.lastAssessmentSubmitDate;
    const bDate = b.lastAssessmentSubmitDate;

    return (aDate > bDate) ? -1 : 1 ;
  }

  export function compareLevel(a: ITeamProcessedData, b: ITeamProcessedData) {
    if (a.avgLevel < b.avgLevel) {
      return -1;
    }
    if (a.avgLevel > b.avgLevel) {
      return 1;
    }
    return 0;
  }

  export function compareNumberOfAssessments(a: ITeamProcessedData, b: ITeamProcessedData) {
    if (a.numberOfAssessments < b.numberOfAssessments) {
      return -1;
    }
    if (a.numberOfAssessments > b.numberOfAssessments) {
      return 1;
    }
    return 0;
  }

  export function compareTeam(a: ITeamProcessedData, b: ITeamProcessedData) {
    if (a.team.toLowerCase() < b.team.toLowerCase()) {
      return -1;
    }
    if (a.team.toLowerCase() > b.team.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  export function compareAssessment(a: ITeamProcessedData, b: ITeamProcessedData) {
    if (!a.questionnaireName) {
      return -1;
    }
    if (!b.questionnaireName) {
      return 1;
    }
    if (a.questionnaireName.toLowerCase() < b.questionnaireName.toLowerCase()) {
      return -1;
    }
    if (a.questionnaireName.toLowerCase() > b.questionnaireName.toLowerCase()) {
      return 1;
    }
    if (a.questionnaireVersion < b.questionnaireVersion) {
      return -1;
    }
    if (a.questionnaireVersion > b.questionnaireVersion) {
      return 1;
    }
    return 0;
  }

  export function comparePercentage(a: ITeamProcessedData, b: ITeamProcessedData) {
    if (!a.avgScore) {
      return -1;
    }
    if (!b.avgScore) {
      return 1;
    }
    if (a.avgScore < b.avgScore) {
      return -1;
    }
    if (a.avgScore > b.avgScore) {
      return 1;
    }
    return 0;
  }

  export function compareLevelD(a: ITeamProcessedData, b: ITeamProcessedData) {

    if (a.avgLevel < b.avgLevel) {
      return 1;
    }
    if (a.avgLevel > b.avgLevel) {
      return -1;
    }
    return 0;
  }

  export function compareNumberOfAssessmentsD(a: ITeamProcessedData, b: ITeamProcessedData) {
    if (a.numberOfAssessments < b.numberOfAssessments) {
      return 1;
    }
    if (a.numberOfAssessments > b.numberOfAssessments) {
      return -1;
    }
    return 0;
  }

  export function compareTeamD(a: ITeamProcessedData, b: ITeamProcessedData) {
    if (a.team < b.team) {
      return 1;
    }
    if (a.team > b.team) {
      return -1;
    }
    return 0;
  }

  export function compareAssessmentD(a: ITeamProcessedData, b: ITeamProcessedData) {
    if (!a.questionnaireName) {
      return 1;
    }
    if (!b.questionnaireName) {
      return -1;
    }
    if (a.questionnaireName.toLowerCase() < b.questionnaireName.toLowerCase()) {
      return 1;
    }
    if (a.questionnaireName.toLowerCase() > b.questionnaireName.toLowerCase()) {
      return -1;
    }
    if (a.questionnaireVersion < b.questionnaireVersion) {
      return 1;
    }
    if (a.questionnaireVersion > b.questionnaireVersion) {
      return -1;
    }
    return 0;
  }

  export function comparePercentageD(a: ITeamProcessedData, b: ITeamProcessedData) {
    if (!a.avgScore) {
      return 1;
    }
    if (!b.avgScore) {
      return -1;
    }
    if (a.avgScore < b.avgScore) {
      return 1;
    }
    if (a.avgScore > b.avgScore) {
      return -1;
    }
    return 0;
  }