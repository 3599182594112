import { ITeamInfo } from '../../../../model';
import { IResponseData } from './common';

/** The intent for using this function is to filter out the content coming
 * from the reports api
 */

export const processAverageScore = (
  responseData: IResponseData,
  teamId: string
) => {
  let sum = 0;
  let total = 0;
  try {
    Object.keys(responseData.teams).forEach((team: string) => {
      if (teamId === 'all' || teamId === team) {
        responseData.teams[team].assessments.forEach((assessment: any) => {
          if (assessment && assessment.result && assessment.result.percentage) {
            sum = sum + assessment.result.percentage;
            total = total + 1;
          }
        });
      }
    });
    return {
      total,
      average: Math.round(sum / total),
    };
  } catch (err) {
    // console.log('Exception:', err);
    return { total: 0, average: 0 };
  }
};

export const processAverageScoreV2 = (
  teamList: ITeamInfo[],
  teamId: string,
  questionnaireIdVersion: string
) => {
  let sum = 0;
  let numberOfAssessments = 0;
  try {
    for(const team of teamList) {
      if ((teamId === 'all' || teamId === team.teamId) && team.assessmentData) {
        if(team.assessmentData && team.assessmentData[questionnaireIdVersion]) {
          numberOfAssessments += team.assessmentData[questionnaireIdVersion].numberOfAssessments;
          sum += team.assessmentData[questionnaireIdVersion].totalScore;
        } else {
          numberOfAssessments += 0;
          sum += 0;
        }
      }
    };
    return {
      total: numberOfAssessments,
      average: numberOfAssessments ? Math.round(sum / numberOfAssessments) : 0
    };
  } catch (err) {
    // console.log('Exception:', err);
    return { total: 0, average: 0 };
  }
};
