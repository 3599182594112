import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getDateFromMilliTimeframe, getFullDate } from '../../../../utils/data';
import { IRootState } from '../../../../reducers';
import { INCIDENT_STATUS_OPEN, INCIDENT_STATUS_RESOLVED, INCIDENT_STATUS_CLOSED, IncidentStatusTypes, IncidentsStatusDataItem } from '../../../../model/metrics/incident';
import { Http } from '../../../../utils';
import { ALL } from '../../../../pages/metrics/metric-select';
import { Box, Container, Grid, Typography } from '@mui/material';
import { Loader } from '../../..';
import { Alert, AlertTitle } from '@mui/lab';
import { Text } from '../../../../common/Language';
import ReactApexChart from 'react-apexcharts';
import { ITrendDataItem } from '../../../../model/metrics/doraData';
import { CommonTooltip } from '../../common/infoTooltip';
import { TIME_ONE_DAY, TIME_ONE_WEEK, TIME_TWO_WEEKS, TIME_THREE_WEEKS, TIME_ONE_MONTH, TIME_SIX_MONTHS, TIME_ONE_YEAR, TIME_YTD } from '../../../../common/common';

export default function IncidentStatusChart(props: any) {
  const stateVariable = useSelector((state: IRootState) => {
    return state;
  });
  const [incidentStatusData, setIncidentStatusData] = useState<IncidentsStatusDataItem[]>([]);
  const [failureMsg, setFailureMsg] = useState(false);
  const [loader, setLoader] = useState(true);
  const [series, setSeries] = useState<any>([]);
  const [userMsg, setUserMsg] = useState('Loading...');
  const history = useHistory();
  const [totalCount, setTotalCount] = useState(0);

  const {setFocusIssueType} = props;

  let date = new Date();
  let yesterday = new Date(date.getTime() - 1 * 24 * 60 * 60 * 1000);
  let one_week = getFullDate(
    new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  let two_weeks = getFullDate(
    new Date(date.getTime() - 2 * 7 * 24 * 60 * 60 * 1000)
  );
  let three_weeks = getFullDate(
    new Date(date.getTime() - 3 * 7 * 24 * 60 * 60 * 1000)
  );
  let one_month = getFullDate(
    new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000)
  );
  let six_months = getFullDate(
    new Date(date.getTime() - 182 * 24 * 60 * 60 * 1000)
  );
  let one_year = getFullDate(
    new Date(date.getTime() - 365 * 24 * 60 * 60 * 1000)
  );
  let ytd = `01 Jan ${date.getFullYear()}`;
  let custom_from_date = getFullDate(props.customDate[0]);
  let custom_to_date = getFullDate(props.customDate[1]);

  useEffect(() => {
    updateData(props.timeline);
  }, [props.customDate, props.timeline]);

  useEffect(() => {
    if (incidentStatusData.length > 0) {
      getIssueCount();
    }
  }, [incidentStatusData])

  useEffect(() => {
    setIncidentStatusData([]);
    setUserMsg('Loading...');
    setSeries([]);
    fetchData();
  }, [props.focusTeam, props.focusService, props.focusSubService, props.focusServiceType, props.incidentStatus, props.incidentPriority, props.selectedDateRange]);

  const fetchData = () => {
    let { timeline, focusTeam, focusService, focusSubService, focusServiceType, joinServiceAndSubService, incidentStatus, incidentPriority, selectedDateRange } = props;
    let url: string = '/api/metrics/incidents/status';
    let joiner = '?';
    if (focusTeam[0] !== ALL) {
      url = `${url}${joiner}teamId=${focusTeam.toString()}`;
      joiner = '&';
    }
    if (focusService[0] !== ALL) {
      url = `${url}${joiner}service=${focusService.join()}`;
      joiner = '&';
    }
    if (focusSubService[0] !== ALL) {
      url = `${url}${joiner}subService=${focusSubService.join()}`;
      joiner = '&';
    }
    if (focusServiceType[0] !== ALL) {
      url = `${url}${joiner}serviceType=${focusServiceType.join()}`;
      joiner = '&';
    }
    if (incidentStatus[0] !== ALL) {
      url = `${url}${joiner}type=${incidentStatus.toString()}`;
      joiner = '&';
    }
    if (incidentPriority[0] !== ALL) {
      url = `${url}${joiner}priority=${incidentPriority.toString()}`;
      joiner = '&';
    }
    url = `${url}${joiner}fromDate=${selectedDateRange.fromDate}&toDate=${selectedDateRange.toDate}`;
    joiner = '&';

    Http.get({
      url,
      state: stateVariable,
    })
      .then((response: any) => {
        if (response) {
          setTimeout(() => {
            setUserMsg('');
          }, 10000);
            setIncidentStatusData(
              response.chartData.sort((a: any, b: any) => {
                return a.timestamp - b.timestamp;
              })
            );
          setLoader(false);
        } else {
          setLoader(false);
          setFailureMsg(true);
        }
      })
      .catch((error) => {
        setLoader(false);
        setFailureMsg(true);
        const perror = JSON.stringify(error);
        const object = JSON.parse(perror);
        if (object.code === 401) {
          history.push('/relogin')
        }
      });
  };

  const getDateRange = (dateRange: any) => {
    let { setIncidentDateRange } = props;
    setIncidentDateRange(dateRange);
  };

  const updateData = (timeline: string) => {
    switch (timeline) {
      case TIME_ONE_DAY:
        getDateRange({
          fromDate: new Date(yesterday).getTime().toString(),
          toDate: new Date(date).getTime().toString(),
        });
        break;
      case TIME_ONE_WEEK:
        getDateRange({
          fromDate: new Date(one_week).getTime().toString(),
          toDate: new Date(date).getTime().toString(),
        });
        break;
      case TIME_TWO_WEEKS:
        getDateRange({
          fromDate: new Date(two_weeks).getTime().toString(),
          toDate: new Date(date).getTime().toString(),
        });
        break;
      case TIME_THREE_WEEKS:
        getDateRange({
          fromDate: new Date(three_weeks).getTime().toString(),
          toDate: new Date(date).getTime().toString(),
        });
        break;
      case TIME_ONE_MONTH:
        getDateRange({
          fromDate: new Date(one_month).getTime().toString(),
          toDate: new Date(date).getTime().toString(),
        });
        break;
      case TIME_SIX_MONTHS:
        getDateRange({
          fromDate: new Date(six_months).getTime().toString(),
          toDate: new Date(date).getTime().toString(),
        });
        break;
      case TIME_ONE_YEAR:
        getDateRange({
          fromDate: new Date(one_year).getTime().toString(),
          toDate: new Date(date).getTime().toString(),
        });
        break;
      case TIME_YTD:
        getDateRange({
          fromDate: new Date(ytd).getTime().toString(),
          toDate: new Date(date).getTime().toString(),
        });
        break;
      case 'all':
        getDateRange({
          fromDate: new Date(one_year).getTime().toString(),
          toDate: new Date(date).getTime().toString(),
        });
        break;
      case 'custom':
        getDateRange({
          fromDate: new Date(custom_from_date).getTime().toString(),
          toDate: new Date(custom_to_date).getTime().toString(),
        });
        break;
      default:
    }
  };

  const handleLegendClick = (chart: any, seriesIndex: any, opts: any) => {
    setFocusIssueType((focusIssueTypeRef: IncidentStatusTypes[]) => {
      const orderedTypes: IncidentStatusTypes[] = [INCIDENT_STATUS_CLOSED, INCIDENT_STATUS_RESOLVED, INCIDENT_STATUS_OPEN];
      const focusIssueTypeCopy = [...focusIssueTypeRef];
      if(focusIssueTypeCopy.indexOf(orderedTypes[seriesIndex]) !== -1) {
        focusIssueTypeCopy.splice(focusIssueTypeCopy.indexOf(orderedTypes[seriesIndex]), 1);
      } else {
        focusIssueTypeCopy.push(orderedTypes[seriesIndex]);
      }
      console.log({focusIssueTypeCopy });
      return focusIssueTypeCopy;
    });
  }

  const options: any = {
    chart: {
      id: 'status_chart',
      height: 250,
      type: 'area',
      /* toolbar: {
        show: false,
      }, */
      group: 'incident'
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
   // colors: ['#00ad6b', '#FFC300', '#808080'],
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      markers: {
        onClick: handleLegendClick,
      },
    },
    /* xaxis: {
      tickAmount: 7
    }, */
    /* xaxis: {
      type: 'datetime',
      tooltip: {
        enabled: false,
      },
      labels: {
        datetimeUTC: false
      }
    }, */
    yaxis: {
      labels: {
        formatter: (value: number) => {
          return value.toFixed();
        },
      },
      title: {
        text: 'Counts',
      },
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy',
      },
      y: {
        formatter: function (value: number) {
          return value;
        },
        title: {
          formatter: (seriesName: string) => {
            let name = seriesName.split('(')[0];
            return `${name}:`;
          }
        },
      },
    }
  };

  const getIssueCount = () => {
    const countOpen: any[] = [];
    const countClosed: any[] = [];
    const countResolved: any[] = [];
    let totalCountOpen = 0;
    let totalCountClosed = 0;
    let totalCountResolved = 0;

    incidentStatusData.map((data: any) => {
      const date = getDateFromMilliTimeframe(data.timestamp, props.timeline, props.customDate[0], props.customDate[1])

      countOpen.push({ x: date, y: data.countOpen });
      totalCountOpen += data.countOpen;

      countClosed.push({ x: date, y: data.countClosed });
      totalCountClosed += data.countClosed;

      countResolved.push({ x: date, y: data.countResolved });
      totalCountResolved += data.countResolved;
    });

    /* trendData.map((data: ITrendDataItem) => {
      const date = getDateFromMilliTimeframe(data.timestamp, props.timeline, props.customDate[0], props.customDate[1])
      trends.push({x: date, 
        y: data.value ? data.value.toFixed(2) : 0
      });
    }); */

    setTotalCount(totalCountOpen + totalCountClosed + totalCountResolved);

    setSeries([
        {
          name: INCIDENT_STATUS_RESOLVED + `(${totalCountResolved})`,
          type: 'area',
          data: countResolved,
          color: '#00ad6b'
        },
         {
          name: INCIDENT_STATUS_CLOSED + `(${totalCountClosed})`,
          type: 'area',
          data: countClosed,
          //color: '#FFC300'
          color: '#4BB2DC'
        }, 
        {
          name: INCIDENT_STATUS_OPEN + `(${totalCountOpen})`,
          type: 'area',
          data: countOpen,
          color: '#808080'
        }
    ]);
  };

  return (
    <div id='chart'>
      <div id='chart-timeline'>
        <Fragment>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant='subtitle2' className='subTitleMetricStyle'>
                <Box fontWeight={700} mb={loader || failureMsg ? 1.5 : 0}>
                  <Text tid='incidentStatus' />
                  <CommonTooltip text='statusChartTooltip'/>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <div style={{float: 'right'}}>
                <span className='doraSubTitles'>
                  <Text tid='count' />
                </span>{' '}
                <span className='countText'>
                  {totalCount}
                </span>
              </div>
            </Grid>
          </Grid>
        </Fragment>
        {loader ? (
          <Container className='loaderStyle'>
            <Loader />
          </Container>
        ) : failureMsg ? (
          <Alert severity='error'>
            <AlertTitle>
              <Text tid='error' />
            </AlertTitle>
            <Text tid='somethingWentWrong' />
          </Alert>
        ) : (
          <div id="status_chart">
          <ReactApexChart
            options={options}
            series={series}
            type='area'
            height={260}
          />
          </div>
        )}
      </div>
    </div>
  );
}
