import React, { useEffect, useState } from 'react';
import { IReqTeamDataItem } from '../../../model/metrics/requirementsData';
import {
  Container,
  Theme,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TableSortLabel,
  TablePagination,
  IconButton,
  useTheme,
  Grid,
  Link,
  Tooltip,
  ThemeProvider,
} from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Loader from '../../loader';
import { Text } from '../../../common/Language';
import { CommonTooltip } from '../common/infoTooltip';
import { Alert, AlertTitle } from '@mui/lab';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../reducers';
import { ALL } from '../../../pages/metrics/metric-select';
import { Http } from '../../../utils';
import { getDaysfromHours } from '../../../utils/data';
import { tooltipTheme } from '../../../common/common';
import { makeStyles, withStyles } from 'tss-react/mui';


declare module '@mui/styles/defaultTheme' {
  // tslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


const StyledTableRow = withStyles(TableRow, (theme: Theme) =>
    ({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: '#f1f1f1',
        },
      },
    })
);

interface HeadCell {
    disablePadding: boolean;
    id: keyof IReqTeamDataItem;
    label: string;
    numeric: boolean;
}

function descendingComparator(a: any, b: any, orderBy: keyof any) {
    if(!a[orderBy]) {
      return 1;
    }
    if(!b[orderBy]) {
      return -1;
    }
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

const headCells: HeadCell[] = [
    { id: 'teamId', numeric: false, disablePadding: true, label: 'team' },
    { id: 'numberOfIssues', numeric: true, disablePadding: true, label: 'numberOfIsues' },
    { id: 'avgLT', numeric: false, disablePadding: false, label: 'avgLTHrs' },
    { id: 'avgCT', numeric: false, disablePadding: false, label: 'avgCTHrs' },
];

function getComparator(
    order: Order,
    orderBy: keyof IReqTeamDataItem
) {
    return order === 'desc'
        ? (a: IReqTeamDataItem, b: IReqTeamDataItem) => descendingComparator(a, b, orderBy)
        : (a: IReqTeamDataItem, b: IReqTeamDataItem) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: any[], comparator: (a: any, b: any) => number) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: keyof IReqTeamDataItem
    ) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof IReqTeamDataItem) => (
      event: React.MouseEvent<unknown>
    ) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align='center'
              sortDirection={orderBy === headCell.id ? order : false}
              className='tableHeadMetrics'
              rowSpan={1}
            >
              <TableSortLabel
                hideSortIcon={true}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <label className='tableHeadMetrics'>
                  <Text tid={headCell.label} />
                </label>
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
}

const useStyles = makeStyles()((theme: Theme) =>
  ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(1),
      boxShadow: '0px 0px 1px #888888',
    },
    table: {
      minWidth: 300,
    },
    container: {
      maxHeight: 208,
    },
  })
);

const useStyles1 = makeStyles()((theme: Theme) =>
  ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  })
);

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const { classes } = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
        size="large">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
        size="large">
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
        size="large">
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
        size="large">
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const TeamsTable = (props: any) => {
  const { classes } = useStyles();
  const styles = useStyles();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IReqTeamDataItem>('teamId');
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const stateVariable = useSelector((state: IRootState) => {
    return state;
  });
  const [reqTeamData, setReqTeamData] = useState<IReqTeamDataItem[]>([]);
  const [failureMsg, setFailureMsg] = useState(false);
  const [loader, setLoader] = useState(true);
  const [loadingTimeline, setLoadingTimeline] = useState(true);
  const history = useHistory();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IReqTeamDataItem
  ) : any => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = reqTeamData.map((n: any) => n.buildNum);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = async() => {
    let { itemType, itemPriority, selectedDateRange } =
      props;
    let url: string = '/api/metrics/reqs/team';
    let joiner = '?';
    
    if (itemType[0] !== ALL) {
      url = `${url}${joiner}type=${itemType.toString()}`;
      joiner = '&';
    }
    if (itemPriority[0] !== ALL) {
      url = `${url}${joiner}priority=${itemPriority.toString()}`;
      joiner = '&';
    }

    url = `${url}${joiner}fromDate=${selectedDateRange.fromDate}&toDate=${selectedDateRange.toDate}`;
    joiner = '&';
    const response : any = await Http.get({
      url,
      state: stateVariable,
    }).catch((error) => {
      setLoader(false);
      setLoadingTimeline(false);
      setFailureMsg(true);
      const perror = JSON.stringify(error);
      const object = JSON.parse(perror);
      if (object.code === 401) {
        history.push('/relogin')
      }
    });

    if (response) {
      setReqTeamData((oldData) => {
        if(oldData.length === 0) {
          return response;
        }
        if(response.length === 0 && itemPriority[0] === ALL && itemType[0] === ALL) {
          console.warn("Teams Table Faulty url:", url);
          return oldData;
        }
        return response
      });
      setLoader(false);
      setLoadingTimeline(false);
    } else {
      setLoader(false);
      setLoadingTimeline(false);
      setFailureMsg(true);
    }
  };

  useEffect(() => {
    setLoadingTimeline(true);
    setReqTeamData([]);
    fetchData();
  }, [
    props.itemType,
    props.itemPriority,
    props.selectedDateRange,
  ]);

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, reqTeamData.length - page * rowsPerPage);

  const renderTable = () => {
    return (
      <Paper className={classes.paper}>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            aria-label='sticky table'
            className={classes.table}
            aria-labelledby='tableTitle'
            size='small'
            // aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={styles}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={Number(reqTeamData)}
            />
            <TableBody style={{ overflow: 'auto', paddingTop: '10px' }}>
              {reqTeamData.length ? (
                stableSort(reqTeamData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const avgLTHrs = (row.avgLT/(3600)).toFixed(1);
                    const avgCTHrs = (row.avgCT/(3600)).toFixed(1);
                    return (
                      <StyledTableRow key={index}>
                        <TableCell align='center'>
                          {row.teamId}
                        </TableCell>
                        <TableCell align='center'>{row.numberOfIssues}</TableCell>
                        <TableCell align='center'>
                            <ThemeProvider theme={tooltipTheme}>
                              <Tooltip
                                title={
                                  `${avgLTHrs} hours`
                                }
                              >
                                <div>
                                  {getDaysfromHours(row.avgLT)}
                                </div>
                              </Tooltip>
                            </ThemeProvider>
                        </TableCell>
                        <TableCell align='center'>
                          <ThemeProvider theme={tooltipTheme}>
                              <Tooltip
                                title={
                                  `${avgCTHrs} hours`
                                }
                              >
                                <div>
                                  {getDaysfromHours(row.avgCT)}
                                </div>
                            </Tooltip>
                          </ThemeProvider>
                        </TableCell>
                      </StyledTableRow>
                    );
                  })
              ) : (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={11} align='center'>
                    {loadingTimeline ? 'Loading...' : 'No Records Found'}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage='Records per page'
          rowsPerPageOptions={[5, 10, 20, 50]}
          component='div'
          count={reqTeamData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelDisplayedRows={({ to, count }) => `${to} of ${count}`}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    );
  };

    return(
      <div className={classes.root}>
        <Grid container>
          <Grid item sm={6}>
            <Box fontWeight={700} mb={props.loader || props.failureMsg ? 2 : 1.5}>
              <Typography variant='subtitle2' style={{fontWeight: 700, display: 'inline-block'}}
                className='subTitleMetricStyle'>
                <Text tid='teams' />
              </Typography>
              <CommonTooltip text='teamwiseLTCTData' />
            </Box>
          </Grid>
          <Grid item sm={6}>
            {/* <div style={{float: 'right'}}>
              <Link style={{ cursor: 'pointer' }} onClick={downloadExcel}>
              <Text tid="downloadCSV" />
              </Link>
            </div> */}
          </Grid>
        </Grid>
        {loader ? (
        <Container className='loaderStyle'>
          <Loader />
        </Container>
        ) : failureMsg ? (
        <Alert severity='error'>
          <AlertTitle>
            <Text tid='error' />
          </AlertTitle>
          <Text tid='somethingWentWrong' />
        </Alert>
        ) : (
        renderTable()
        )}
      </div>
    )
}

export default TeamsTable;