import { ITeamInfo, ITeamProcessedData } from "../../model";

const getQuestionnaireName = (qId: string, qVersion: string, questionnaires: any) => {
    let name = ''
    for(const questionnaire of questionnaires) {
        if(questionnaire.questionnaireId === qId) {
            name = questionnaire.displayName;
        }
    }
    return name;
}

export const getProcessedData = (teamListCopy: ITeamInfo[], questionnaires: any) => {
    const ret: ITeamProcessedData[] = [];
    for (const teamItem of teamListCopy) {
        if(teamItem.assessmentData) {
            const keys = Object.keys(teamItem.assessmentData);
            for (const key of keys) {
                const data: ITeamProcessedData = {
                    team: teamItem.teamName,
                    teamId: teamItem.teamId,
                    questionnaireName: '',
                    questionnaireId: '',
                    numberOfAssessments: 0,
                    questionnaireVersion: 0,
                    avgScore: 0,
                    avgLevel: '',
                    lastAssessmentSubmitDate: 0
                };
                const keySplit = key.split('_')
                const questionnaireId = keySplit[0];
                const questionnaireVersion = keySplit[1];
                data.questionnaireName = `${getQuestionnaireName(questionnaireId, questionnaireVersion, questionnaires)}`;
                console.log(data.questionnaireName )
                data.questionnaireId = questionnaireId;
                data.questionnaireVersion = parseInt(questionnaireVersion, 10);
                data.numberOfAssessments = teamItem.assessmentData[key].numberOfAssessments;
                data.avgScore = Math.round(teamItem.assessmentData[key].totalScore / teamItem.assessmentData[key].numberOfAssessments);
                data.avgLevel = teamItem.assessmentData[key].averageLevel;
                data.lastAssessmentSubmitDate = teamItem.assessmentData[key].lastAssessmentSubmitDate;
                ret.push(data);
            }
        } else {
            continue;
        }
    }
    return ret;
}