import * as React from 'react';
import { Provider } from 'react-redux';
import DoitRight from './App';
import configureStore from './configureStore';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#042E5B',
    },
  },
});

const { store, persistor } = configureStore();

function ReduxRoot() {
  return (
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <DoitRight />
        </ThemeProvider>
      </Provider>
    </PersistGate>
  );
}

export default ReduxRoot;
