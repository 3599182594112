import React from 'react';
import { Box, Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const AssessmentTable = () => {
  const rows = [
    {
      category: "IT Operations Maturity",
      description: "Measures the sophistication of IT systems & their management along with alignment with business goals",
    },
    {
      category: "SRE Maturity",
      description: "Evaluates the adoption of SRE practices to improve system reliability, automation and incident management. Tools used for Observability and Operations. Identify opportunities for further automation using existing tools.",
    },
    {
      category: "AIOps Readiness",
      description: "Assesses the organization's ability to leverage AI/ML for proactive & predictive IT operations and automation.",
    },
    {
      category: "Applications Landscape",
      description: "Overview of software systems, their integrations, scalability, reliability, maintainability, operability, evolvability.",
    },
    {
      category: "DevOps",
      description: "Evaluate the DevOps maturity, including CI/CD pipeline, tools, testing automation, branching strategies and version control, build release documentation and rollback processes.",
    },
    {
      category: "Infrastructure & Database",
      description: 'Examines the scalability, performance and resilience of data center(s) hardware "capacity, performance, and utilization", network and database systems Plus Backup & Recovery ensuring alignment with business continuity and disaster recovery (BCDR) strategies, Archiving system, DR capabilities, RTO & RPO, Runbook & DR Drill procedure, used tools and methodologies.',
    },
    {
        category: "Security Compliance",
        description: 'Ensures adherence to security regulations, standards and internal policies to protect data and systems',
      },
      {
        category: "Governance & Performance Management",
        description: 'Review existing IT operational processes, SOPs. Assess the adoption and effectiveness of ITIL practices in incident, problem, change, release, and service request management, Review ITSM tools and platforms used for service desk, ticketing, and knowledge management',
      },
      {
        category: "Service Level Agreements (SLAs)",
        description: 'Analyze current SLAs with internal stakeholders and external vendors. Evaluate adherence to SLAs and identify areas where improvements are needed',
      },
      {
        category: "Performance Metrics",
        description: 'Collect data on key performance indicators (KPIs) such as uptime, mean time to repair (MTTR), mean time between failures (MTBF), service request response times, and incident resolution times',
      },
      {
        category: "Monitoring and Alerting",
        description: 'Evaluate the monitoring tools and frameworks in place. Review alerting mechanisms, escalation procedures, and their effectiveness in proactive incident management',
      },
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Assessment Scope & Outcomes
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><Typography variant="h5">Categories</Typography></TableCell>
              <TableCell><Typography variant="h5">Description</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.category}</TableCell>
                <TableCell>{row.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default AssessmentTable;