import { Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { withStyles } from 'tss-react/mui';

export const LightTooltip = withStyles(Tooltip, (theme: Theme) => 
  ({
    tooltip: {
    //  position: 'absolute',
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    }
  })
);
