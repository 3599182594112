export const INCIDENT_STATUS_OPEN = 'Open';
export const INCIDENT_STATUS_RESOLVED = 'Resolved';
export const INCIDENT_STATUS_CLOSED = 'Closed';

export type IncidentStatusTypes = "Open" | "Resolved" | "Closed";

export interface IncidentsStatusDataItem {
  countClosed: number;
  countOpen: number;
  countResolved: number;
  timestamp: number;
}

export interface IIncidentListDataItem {
  endTime?: number;
  itemId: string;
  itemPriority: string;
  itemType: string;
  projectName: string;
  service: string;  // For Opsgenie Service is not the Opsgenie service but the service belonging to the team in DoItRight
  startTime: number;
  status: string;
  teamId: string;
  url: string;
}
