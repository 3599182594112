import React from 'react';
import { ThemeProvider, Theme, Typography, Tooltip } from '@mui/material';
import { tooltipTheme } from '../../../common/common';
import InfoIcon from '@mui/icons-material/Info';
import '../../../css/metrics/style.css';
import { Text } from '../../../common/Language';


declare module '@mui/styles/defaultTheme' {
  // tslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


export const CommonTooltip = (props: any) => {
    return (
        <ThemeProvider theme={tooltipTheme}>
            <Tooltip
            title={
                <Typography className='tooltipTitleStyle'>
                    <Text tid={props.text} />
                </Typography>
            }
            >
            <InfoIcon className='reqInfoIconStyle' />
            </Tooltip>
        </ThemeProvider>
    );
}
  