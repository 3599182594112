import React from 'react';
import { ThemeProvider, Typography, Tooltip } from '@mui/material';
import { tooltipTheme } from '../common';
import InfoIcon from '@mui/icons-material/Info';
import { Text } from '../Language';
import '../../css/metrics/style.css';

export const TimelineTooltip = (
  <ThemeProvider theme={tooltipTheme}>
    <Tooltip
      title={
        <Typography className='tooltipTitleStyle'>
          <Text tid='clickChartLegendLabelsToFilterTheRecords' />
        </Typography>
      }
    >
      <InfoIcon className='infoIconStyle' />
    </Tooltip>
  </ThemeProvider>
);
