import React, { Fragment, useEffect, useState } from 'react';
import {
  Container,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Input,
  ListItemText,
  Checkbox,
  Typography,
  Paper,
} from '@mui/material';
import {
  DeploymentFrequency,
  LeadTime,
  MTTR,
  ChangeFailureRate,
} from '../../../components/doraMetrics';
import DoraMetricsTimeline from './Timeline';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import MetricsList from './metricsList';
import { useSelector } from 'react-redux';
import { Http } from '../../../utils';
import { IRootState } from '../../../reducers';
import { Text } from '../../../common/Language';
import '../../../css/metrics/style.css';
import { IServiceInfo, ITeamInfo } from '../../../model';
import TeamAndServiceSelection from './TeamAndServiceSelection';
import { makeStyles } from 'tss-react/mui';
import { TIME_ONE_DAY } from '../../../common/common';

export const ALL = 'All';

const useStyles = makeStyles()((theme) => ({
  containerTop: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    position: 'fixed',
    top: '100px',
    zIndex: 100,
    backgroundColor: '#ffffff'
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    position: 'relative',
    top: '120px',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    paddingBottom: 0,
    border: '1px solid #b6b6b6',
  },
  doraMetricsContainer: {
    border: '1px solid #b1b1b1',
    marginTop: '20px',
    paddingTop: '10px',
    paddingBottom: '24px',
    paddingRight: '24px',
    borderRadius: '5px',
    boxShadow: '0px 0px 2px #a2a2a2',
    backgroundColor: '#e0e0e0',
  },
  fixedHeight: {
    height: 380,
  },
  toolbar: {
    fontSize: '12px !important',
    fontWeight: 'bold',
    display: 'initial',
  },
  viewMoreText: {
    color: 'blue',
    cursor: 'pointer',
    fontSize: '14px',
  },
  doraMetricsLinkText: {
    color: 'blue',
    cursor: 'pointer',
    float: 'right',
  },
}));

let now = new Date();
let todayBegin = new Date(now.getFullYear(), now.getMonth(), now.getDate());
let todayEnd = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate(),
  23,
  59,
  59,
  999
);

const MetricDetails = (props: any) => {
  const teamParam = props.match.params.teamId ? props.match.params.teamId : ALL;
  const { classes } = useStyles();
  const [dateRange, setDateRange] = useState({ fromDate: '', toDate: '' });
  const [focusTeam, setFocusTeam] = useState<string[]>([teamParam]);
  const [focusService, setFocusService] = useState<string[]>([ALL]);
  const [focusSubService, setFocusSubService] = useState<string[]>([ALL]);
  const [focusServiceType, setFocusServiceType] = useState<string[]>([ALL]);
  const [customDate, setCustomDate] = useState([todayBegin, todayEnd]);
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [metricType, setMetricType] = useState('doraMetrics');
  const [timeline, setTimeline] = useState(TIME_ONE_DAY);
  const [queryParams, setQueryParams] = useState('')
  const [verticalScroll, setVerticalScroll] = useState(0);
  const [serviceList, setServiceList] = useState<IServiceInfo[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setMetricType(props.metricType);
  }, []);

  useEffect(() => {
    getQueryParams()
  }, [dateRange, focusTeam, focusService, focusSubService, focusServiceType])

  const getQueryParams = () => {
    let params: string = '';
    let joiner = '?';
    if (focusTeam[0] !== ALL) {
      params = `${params}${joiner}teamId=${focusTeam.join()}`;
      joiner = '&';
    }
    /* if (focusService[0] !== ALL && focusSubService[0] !== ALL) {
      params = `${params}${joiner}service=${joinServiceAndSubService()}`;
      joiner = '&';
    } else  */
    if (focusService[0] !== ALL) {
      params = `${params}${joiner}service=${focusService.join()}`;
      joiner = '&';
    }
    if (focusSubService[0] !== ALL) {
      params = `${params}${joiner}subService=${focusSubService.join()}`;
      joiner = '&';
    }
    if (focusServiceType[0] !== ALL) {
      params = `${params}${joiner}serviceType=${focusServiceType.join()}`;
      joiner = '&';
    }
    if (timeline !== TIME_ONE_DAY) {
      params = `${params}${joiner}fromDate=${dateRange.fromDate}&toDate=${dateRange.toDate}`;
      joiner = '&';
    }
    setQueryParams(params);
  }

  const joinServiceAndSubService = (): string => {
    let dataList: string[] = [];
    focusService.forEach((serviceId: string) => {
      let service: any = serviceList.find((elem: any) => elem.id === serviceId);
      if ((service !== undefined) && service.services) {
        service.services.forEach((subService: any) => {
          if (focusSubService.indexOf(subService.id) > -1) {
            dataList.push(`${serviceId}/${subService.id}`);
          }
        });
      } else {
        dataList.push(serviceId);
      }
    });
    return dataList.join();
  };

  useEffect(() => {
    setMetricType(props.metricType);
    window.scrollTo(0, 0);
    switch (props.metricType) {
      case 'build':
        setVerticalScroll(0);
        break;
      case 'requirements':
        setVerticalScroll(870);
        break;
      case 'quality':
        setVerticalScroll(2000);
        break;
      case 'repository':
        setVerticalScroll(436);
      break;
      case 'incidents':
        setVerticalScroll(2459);
      break;
      default:
      // code block
    }
  }, [props.metricType, props.metricSelection]);

  const getDoraMetricsCustomDates = (dateRange: any) => {
    setCustomDate(dateRange);
    setTimeline('custom');
  };

  const updateTimelineData = (timeline: any) => {
    setTimeline(timeline);
    setCustomDate([todayBegin, todayEnd]);
  };

  const teamServiceSelection = () => {
    return (
      <TeamAndServiceSelection
        focusTeam={focusTeam}
        focusService={focusService}
        focusSubService={focusSubService}
        focusServiceType={focusServiceType}
        focusPipelineStatus={[]}
        setFocusTeam={setFocusTeam}
        setFocusSubService={setFocusSubService}
        setFocusService={setFocusService}
        setFocusServiceType={setFocusServiceType}
        setFocusPipelineStatus={() => {}}
        setServiceListParent={setServiceList}
      />
    )
  }

  const doraMetricsPage = () => {
    return (
      <Grid container>
        <Grid container className={classes.doraMetricsContainer} spacing={3}>
          <Grid
            item
            xs={12}
            md={8}
            lg={8}
          >
            <Typography variant='h6'>
              <Text tid='doraMetrics' />:
              <div className={classes.toolbar}>
                <DoraMetricsTimeline
                  timeline={timeline}
                  updateTimelineData={(timeline: any) =>
                    updateTimelineData(timeline)
                  }
                  getDoraMetricsCustomDates={(dateRange: any) =>
                    getDoraMetricsCustomDates(dateRange)
                  }
                  customDate={customDate}
                />
              </div>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={4}></Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              className={fixedHeightPaper}
              style={{ backgroundColor: '#f1f4ff' }}
            >
              <DeploymentFrequency
                timeline={timeline}
                customDate={customDate}
                queryParams={queryParams}
                getDateRange={(dateRange: any) => setDateRange(dateRange)}
              />
              <InputLabel
                onClick={() => {
                  setMetricType('build');
                  setVerticalScroll(0);
                }}
                className={classes.viewMoreText}
              >
                <Text tid='viewMore' />
              </InputLabel>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Paper className={fixedHeightPaper}>
              <LeadTime queryParams={queryParams} />
              <InputLabel
                onClick={() => {
                  setMetricType('build');
                  setVerticalScroll(0);
                }}
                className={classes.viewMoreText}
              >
                <Text tid='viewMore' />
              </InputLabel>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Paper className={fixedHeightPaper}>
              <MTTR queryParams={queryParams} />
              <InputLabel
                onClick={() => {
                  setMetricType('quality');
                  setVerticalScroll(1650);
                }}
                className={classes.viewMoreText}
              >
                <Text tid='viewMore' />
              </InputLabel>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              className={fixedHeightPaper}
              style={{ backgroundColor: '#f1f4ff' }}
            >
              <ChangeFailureRate queryParams={queryParams} />
              <InputLabel
                onClick={() => {
                  setMetricType('gitRepository');
                  setVerticalScroll(436);
                }}
                className={classes.viewMoreText}
              >
                <Text tid='viewMore' />
              </InputLabel>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    );
  };  

  return (
    <Fragment>
      {teamServiceSelection()}
      <Container maxWidth='lg' className={classes.container}>
        {metricType === 'doraMetrics' && props.metricType === 'doraMetrics' ? (
          doraMetricsPage()
        ) : (
          <MetricsList
            focusTeam={focusTeam}
            focusService={focusService}
            focusSubService={focusSubService}
            focusServiceType={focusServiceType}
            joinServiceAndSubService={joinServiceAndSubService}
            verticalScroll={verticalScroll}
          />
        )}
      </Container>
    </Fragment>
  );
};

export default withRouter(MetricDetails);
