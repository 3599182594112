import React, { useState, useEffect, Fragment } from 'react';
import { Box, Typography, Container, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { Http } from '../../../utils';
import { IRootState } from '../../../reducers';
import { ALL } from '../../../pages/metrics/metric-select';
import { IReqLTCTDataItem } from '../../../model/metrics/requirementsData';
import Loader from '../../loader';
import { Alert, AlertTitle } from '@mui/lab';
import { Text } from '../../../common/Language';
import '../../../css/metrics/style.css';
import { CommonTooltip } from '../common/infoTooltip';
import { ITrendDataItem } from '../../../model/metrics/doraData';
import { getDateFromMilliTimeframe, getDateTimeFromMilli } from '../../../utils/data';

export default function LeadAndCycleTimeChart(props: any) {
  const stateVariable = useSelector((state: IRootState) => {
    return state;
  });
  const [ltctData, setLtCtData] = useState<IReqLTCTDataItem[]>([]);
  const [averageLt, setAverageLt] = useState(0);
  const [averageCt, setAverageCt] = useState(0);
  const [series, setSeries] = useState<any>([]);
  const [trendData, setTrendData] = useState<
    ITrendDataItem[]
  >([]);
  const [failureMsg, setFailureMsg] = useState(false);
  const [loader, setLoader] = useState(true);
  const [userMsg, setUserMsg] = useState('Loading...');
  const history = useHistory();

  useEffect(() => {
    fetchData();
    setLtCtData([]);
    setSeries([]);
    setTrendData([]);
    setUserMsg('Loading...');
  }, [
    props.focusTeam,
    props.focusService,
    props.focusSubService,
    props.focusServiceType,
    props.selectedDateRange,
    props.itemType,
    props.itemPriority,
  ]);

  useEffect(() => {
    if(ltctData.length > 0 && trendData.length > 0) {
      getLtCtCount();
    }
  }, [ltctData, trendData])

  const fetchData = () => {
    let { timeline, focusTeam, focusService, focusSubService, focusServiceType, joinServiceAndSubService, itemType, itemPriority, selectedDateRange } = props;
    
    let url: string = '/api/metrics/reqs/ltct';
    let joiner = '?';
    if (focusTeam[0] !== ALL) {
      url = `${url}${joiner}teamId=${focusTeam.toString()}`;
      joiner = '&';
    }
    /* if (focusService[0] !== ALL && focusSubService[0] !== ALL) {
      url = `${url}${joiner}service=${joinServiceAndSubService()}`;
      joiner = '&';
    } else */
    if (focusService[0] !== ALL) {
      url = `${url}${joiner}service=${focusService.join()}`;
      joiner = '&';
    }
    if (focusSubService[0] !== ALL) {
      url = `${url}${joiner}subService=${focusSubService.join()}`;
      joiner = '&';
    }
    if (focusServiceType[0] !== ALL) {
      url = `${url}${joiner}serviceType=${focusServiceType.join()}`;
      joiner = '&';
    }
    if (itemType[0] !== ALL) {
      url = `${url}${joiner}type=${itemType.toString()}`;
      joiner = '&';
    }
    if (itemPriority[0] !== ALL) {
      url = `${url}${joiner}priority=${itemPriority.toString()}`;
      joiner = '&';
    }

    url = `${url}${joiner}fromDate=${selectedDateRange.fromDate}&toDate=${selectedDateRange.toDate}`;
    joiner = '&';

    Http.get({
      url,
      state: stateVariable,
    })
      .then((response: any) => {
        if (response) {
          setTimeout(() => {
            setUserMsg('');
          }, 10000);
          setLtCtData(
            response.chartData.sort((a: any, b: any) => {
              return a.timestamp - b.timestamp;
            })
          );
          setTrendData(
            response.trendData.sort((a: ITrendDataItem, b: ITrendDataItem) => {
              return a.timestamp - b.timestamp;
            })
          );
          setAverageCt(response.averageCycleTime);
          setAverageLt(response.averageLeadTime);
          setLoader(false);
        } else {
          setLoader(false);
          setFailureMsg(true);
        }
      })
      .catch((error) => {
        setLoader(false);
        setFailureMsg(true);
        const perror = JSON.stringify(error);
        const object = JSON.parse(perror);
        if (object.code === 401) {
          history.push('/relogin')
        }
      });
  };

  const options: any = {
    chart: {
      id: 'LT_CT_chart',
      type: 'line',
      height: 250,
      group: 'requirements',
    /*   toolbar: {
        //autoSelected: 'pan',
        show: false,
      }, */
    },
    //colors: ['#FFC300', '#CA6F1E', '#F26A1E', '#EDD041'],
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 2,
    },
    /* xaxis: {
     type: 'datetime',
      labels: {
        datetimeUTC: false,
      },
       tooltip: {
        enabled: false,
      }, 
    }, */
    /* xaxis: {
      tickAmount: 7
    }, */
    yaxis: {
      labels: {
        formatter: (value: number) => {
          return value.toFixed();
        },
      },
       title: {
        text: 'Time in Hours',
      }, 
    },
    tooltip: {
      //followCursor: true,
      x: {
        format: 'dd MMM yyyy',
      },
      y: {
        formatter: function (value: number) {
          if(value) {
            if (value > 24) {
              let days: string = Math.floor(value / 24).toString();
              let hrs: string = (value % 24).toFixed();
              return days + ' days ' + hrs + ' hrs';
            } else {
              return value.toFixed() + ' hrs';
            }
          }
        },
      },
    }
     /* noData: {
      text: userMsg,
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#000000',
        fontSize: '12.5px',
        fontFamily: 'Helvetica, Arial, sans-serif',
      },
    },  */
  };

  const getLtCtCount = () => {
    const totalLeadTime: any[] = [];
    const totalCycleTime: any[] = [];
    const trends: any[] = [];

    ltctData.map((data: any) => {
      const dateTime = getDateFromMilliTimeframe(data.timestamp, props.timeline, props.customDate[0], props.customDate[1]);

      totalLeadTime.push({
        x: dateTime,
        y: data.issueCount
          ? Math.round(data.totalLeadTime / (data.issueCount * 60))
          : 0, //converting to average hours
      });

      totalCycleTime.push({
        x: dateTime,
        y: data.issueCount
          ? Math.round(data.totalCycleTime / (data.issueCount * 60))
          : 0, //converting to average hours
      });
    });

    trendData.map((data: ITrendDataItem) => {
      const dateTime = getDateFromMilliTimeframe(data.timestamp, props.timeline, props.customDate[0], props.customDate[1]);
      trends.push({x: dateTime, 
        y: data.value ? Math.round(data.value/60) : 0
      });
    });

    setSeries([
      {
        name: 'Lead Time',
        type: 'area',
        data: totalLeadTime,
        color: '#FFC300',
      },
      {
        name: 'Cycle Time',
        type: 'area',
        data: totalCycleTime,
        color: '#CA6F1E'
      },
      {
        name: 'Trends',
        type: 'line',
        data: trends,
        color: '#090909'
      },
    ])
  };

  return (
    <div id='chart'>
      <div id='chart-timeline'>
        <Fragment>
          <Grid container>
            <Grid item xs={4}>
            <Typography variant='subtitle2' className='subTitleMetricStyle'>
              <Box fontWeight={700} mb={loader || failureMsg ? 1.5 : 0}>
                <Text tid='leadTime-CycleTime' />
                <CommonTooltip text='requirementsLTCTChartTooltip'/>
              </Box>
            </Typography>
            </Grid>
            <Grid item xs={8}>
              <Grid container>
                <Grid item xs={6}>
                  <span className='doraSubTitles'>
                    <Text tid='averageLeadTime' />:
                  </span>{' '}
                  <span className='countText'>
                    {(averageLt / 60).toFixed(1)} hours
                  </span>
                </Grid>
                <Grid item xs={6}>
                  <span className='doraSubTitles'>
                    <Text tid='averageCycleTime' />
                  </span>{' '}
                  <span className='countText'>
                    {(averageCt / 60).toFixed(1)} hours
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          
        </Fragment>
        {loader ? (
          <Container className='loaderStyle'>
            <Loader />
          </Container>
        ) : failureMsg ? (
          <Alert severity='error'>
            <AlertTitle>
              <Text tid='error' />
            </AlertTitle>
            <Text tid='somethingWentWrong' />
          </Alert>
        ) : (
          <div id="LT_CT_chart">
          <ReactApexChart
            options={options}
            series={series}
            type='area'
            height={260}
          />
          </div>
        )}
      </div>
    </div>
  );
}
