// tslint:disable: jsx-no-lambda

import React from 'react';
import { adaptV4Theme } from '@mui/material/styles';
import {
  Typography,
  createTheme,
  Container,
  Button,
  Theme,
} from '@mui/material';
import { ThemeProvider } from '@mui/styles';
import { Text } from '../../../common/Language';
import { makeStyles } from 'tss-react/mui';


declare module '@mui/styles/defaultTheme' {
  // tslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


interface ILeftPane {
  redirectUrl: string;
}

const theme = createTheme(adaptV4Theme({
  typography: {
    h2: {
      fontSize: '7',
    },
  },
}));

const useStyles = makeStyles()((theme) => ({
  root: {
    textAlign: 'left',
    margin: '20px',
    marginTop: '108px',
  },
  secondaryText: {
    fontWeight: 'normal',
    fontSize: '32px',
  },
  homeText: {
    fontWeight: 'normal',
    fontSize: '18px',
  },
  button: {
    margin: theme.spacing(2),
    marginLeft: '0px',
    // backgroundColor: '#042E5B',
    // '&:hover, &:focus, &:active': {
    //   backgroundColor: '#042E5B',
    // },
  },
}));

const LABEL = <Text tid='measureYourself' />;
const LeftPane = (props: ILeftPane) => {
  const { classes } = useStyles();

  const onClick = () => {
    window.open(
      props.redirectUrl,
      '_self',
      `toolbar=no, location=no, directories=no, status=no, menubar=no,
            scrollbars=no, resizable=no, copyhistory=no, width=${500},
            height=${5000}, top=${300}, left=${300}`
    );
  };
  return (
    <Container className={classes.root}>
        {/* <ThemeProvider theme={theme}>
          <Typography variant='h2'>
            <strong>
              DevSec Ops <Text tid='journey' />
              &nbsp;...
            </strong>
          </Typography>
        </ThemeProvider> */}
      <Typography className={classes.homeText}>
        
      Mobily Infotech India Pvt. Ltd.(MIT) is an extended IT arm of multi-billion dollar Mobily KSA leveraging technology to manage business processes and enable end users to improve efficiencies and effectiveness by adopting a business-driven framework to deliver new services rapidly.
Mobily Infotech is looking to bring automation, new technology adoption and moving mobily IT operation from standard operation to new AI operation. This will have a direct  impact on Mobily customers, CX, revenue and growth.
To achieve the above objectives, Nomiso is proposing assessment of the current landscape and provide detailed recommendations and road map to meet above objectives 

      </Typography>
      {/* <Button
        onClick={onClick}
        variant='contained'
        size='large'
        color='primary'
        className={classes.button}
      >
        {LABEL}
      </Button> */}
    </Container>
  );
};
export default LeftPane;
