// tslint:disable: all

import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import {
  ThemeProvider,
  Theme,
  Typography,
  Tooltip,
  TableSortLabel,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import 'react-circular-progressbar/dist/styles.css';
import { IAssessmentListItem } from '../../../../model';
import { withRouter } from 'react-router-dom';
import { getDateTime } from '../../../../utils/data';
import { tooltipTheme } from '../../../../common/common';
import { Text } from '../../../../common/Language';
import '../../../../css/assessments/style.css';
import { makeStyles } from 'tss-react/mui';


declare module '@mui/styles/defaultTheme' {
  // tslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


interface IAssessmentArrayItem {
  data: IAssessmentListItem;
  team: string;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    border: '18px',
  },
  firstColumn: {
    maxWidth: '200px',
    overflow: 'hidden',
  },
}));

function CategoryBarChartEvent(props: any) {
  const { classes } = useStyles();
  const [assessmentArray, setAssessmentArray] = useState<
    IAssessmentArrayItem[]
  >([]);
  const [sortedAssessmentArray, setSortedAssessmentArray] = useState<
    IAssessmentArrayItem[]
  >([]);

  /* Order related changes */
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState('date');
  /* Initialization Order related variables ends here */

  useEffect(() => {
    let tempAssessmentArray: IAssessmentArrayItem[] = [];
    Object.keys(props.responseData.teams).forEach((team: string) => {
      if (props.focusTeam === 'all' || team === props.focusTeam) {
        props.responseData.teams[team].assessments.forEach(
          (assessment: IAssessmentListItem) => {
            if (
              assessment &&
              assessment.result &&
              assessment.result.categoryWiseResults &&
              assessment.result.categoryWiseResults[props.focusCategory]
            ) {
              let item: IAssessmentArrayItem = { data: assessment, team: team };
              tempAssessmentArray.push(item);
            }
          }
        );
      }
    });
    setAssessmentArray(tempAssessmentArray);
  }, []);

  useEffect(() => {
    if (assessmentArray.length > 0) {
      let tempSortedAssessmentArray = assessmentArray;
      tempSortedAssessmentArray.sort(compareDateD);
      setSortedAssessmentArray(tempSortedAssessmentArray);
    }
  }, [assessmentArray]);

  useEffect(() => {
    if (assessmentArray.length > 0) {
      let tempSortedAssessmentArray = [...sortedAssessmentArray];
      if (order === 'asc') {
        if (orderBy === 'name') {
          setSortedAssessmentArray(tempSortedAssessmentArray.sort(compareName));
        }
        if (orderBy === 'date') {
          setSortedAssessmentArray(tempSortedAssessmentArray.sort(compareDate));
        }
        if (orderBy === 'team') {
          setSortedAssessmentArray(tempSortedAssessmentArray.sort(compareTeam));
        }
        if (orderBy === 'score') {
          setSortedAssessmentArray(
            tempSortedAssessmentArray.sort(compareScore)
          );
        }
      }
      if (order === 'desc') {
        if (orderBy === 'name') {
          setSortedAssessmentArray(
            tempSortedAssessmentArray.sort(compareNameD)
          );
        }
        if (orderBy === 'date') {
          setSortedAssessmentArray(
            tempSortedAssessmentArray.sort(compareDateD)
          );
        }
        if (orderBy === 'team') {
          setSortedAssessmentArray(
            tempSortedAssessmentArray.sort(compareTeamD)
          );
        }
        if (orderBy === 'score') {
          setSortedAssessmentArray(
            tempSortedAssessmentArray.sort(compareScoreD)
          );
        }
      }
    }
  }, [order, orderBy]);

  const getLevel = (percentage: number) => {
    // console.log(percentage);
    const userLevels = props.responseData.userLevels;
    let result = '';
    userLevels.forEach((level: any) => {
      if (percentage <= level.upperLimit && percentage >= level.lowerLimit) {
        result = level.name;
      }
    });
    return result;
  };

  function compareName(a: any, b: any) {
    if (a.data.userId < b.data.userId) {
      return -1;
    }
    if (a.data.userId > b.data.userId) {
      return 1;
    }
    return 0;
  }

  function compareDate(a: any, b: any) {
    if (a.data.dateSubmit < b.data.dateSubmit) {
      return -1;
    }
    if (a.data.dateSubmit > b.data.dateSubmit) {
      return 1;
    }
    return 0;
  }

  function compareTeam(a: any, b: any) {
    if (a.team < b.team) {
      return -1;
    }
    if (a.team > b.team) {
      return 1;
    }
    return 0;
  }

  function compareScore(a: any, b: any) {
    if (
      a.data.result!.categoryWiseResults[props.focusCategory].percentage <
      b.data.result!.categoryWiseResults[props.focusCategory].percentage
    ) {
      return -1;
    }
    if (
      a.data.result!.categoryWiseResults[props.focusCategory].percentage >
      b.data.result!.categoryWiseResults[props.focusCategory].percentage
    ) {
      return 1;
    }
    return 0;
  }

  function compareNameD(a: any, b: any) {
    if (a.data.userId < b.data.userId) {
      return 1;
    }
    if (a.data.userId > b.data.userId) {
      return -1;
    }
    return 0;
  }

  function compareDateD(a: any, b: any) {
    if (a.data.dateSubmit < b.data.dateSubmit) {
      return 1;
    }
    if (a.data.dateSubmit > b.data.dateSubmit) {
      return -1;
    }
    return 0;
  }

  function compareTeamD(a: any, b: any) {
    if (a.team < b.team) {
      return 1;
    }
    if (a.team > b.team) {
      return -1;
    }
    return 0;
  }

  function compareScoreD(a: any, b: any) {
    if (
      a.data.result!.categoryWiseResults[props.focusCategory].percentage <
      b.data.result!.categoryWiseResults[props.focusCategory].percentage
    ) {
      return 1;
    }
    if (
      a.data.result!.categoryWiseResults[props.focusCategory].percentage >
      b.data.result!.categoryWiseResults[props.focusCategory].percentage
    ) {
      return -1;
    }
    return 0;
  }

  const handleRequestSort = (property: string) => {
    if (orderBy === property) {
      setOrder(order === 'asc' ? 'desc' : 'asc');
    } else {
      setOrder('asc');
      setOrderBy(property);
    }
  };

  return (
    <Container maxWidth='md' component='div' className='containerRoot'>
      <div style={{ width: '97%' }}>
        <Typography variant='h4' color='primary' gutterBottom>
          {props.focusCategory}
        </Typography>
      </div>
      <Paper className={classes.root}>
        <Table className='table'>
          <TableHead className='tableHead'>
            <TableRow>
              <TableCell className='tableHeadCell'>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={() => {
                    handleRequestSort('name');
                  }}
                >
                  <Typography className='tableHeadText'>
                    <Text tid='takenBy' />
                  </Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell align='center' className='tableHeadCell'>
                <TableSortLabel
                  active={orderBy === 'date'}
                  direction={orderBy === 'date' ? order : 'asc'}
                  onClick={() => {
                    handleRequestSort('date');
                  }}
                >
                  <Typography className='tableHeadText'>
                    <Text tid='dateSubmitted' />
                  </Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell align='center' className='tableHeadCell'>
                <TableSortLabel
                  active={orderBy === 'team'}
                  direction={orderBy === 'team' ? order : 'asc'}
                  onClick={() => {
                    handleRequestSort('team');
                  }}
                >
                  <Typography className='tableHeadText'>
                    <Text tid='team' />
                  </Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell align='center' className='tableHeadCell'>
                <TableSortLabel
                  active={orderBy === 'score'}
                  direction={orderBy === 'score' ? order : 'asc'}
                  onClick={() => {
                    handleRequestSort('score');
                  }}
                >
                  <Typography className='tableHeadText'>
                    <Text tid='score' />
                  </Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell align='center' className='tableHeadCell'>
                <Typography className='tableHeadText'>Level</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedAssessmentArray.map((row, index) => (
              <TableRow
                key={index}
                style={
                  index % 2
                    ? { background: '#EFEFEF' }
                    : { background: 'white' }
                }
              >
                <TableCell
                  component='th'
                  scope='row'
                  className={classes.firstColumn}
                >
                    <ThemeProvider theme={tooltipTheme}>
                      <Tooltip
                        title={
                          <Typography className='tooltipTitleStyle'>
                            {row.data.userId ? row.data.userId : 'NA'}
                          </Typography>
                        }
                      >
                        <Typography className='tableBodyText'>
                          {row.data.userId ? row.data.userId : 'NA'}
                        </Typography>
                      </Tooltip>
                    </ThemeProvider>
                </TableCell>
                <TableCell align='center'>
                  <Typography className='tableBodyText'>
                    {row.data.dateSubmit
                      ? getDateTime(row.data.dateSubmit)
                      : row.data.date
                      ? getDateTime(row.data.date)
                      : '-'}
                  </Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography className='tableBodyText'>{row.team}</Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography className='tableBodyText'>
                    {row.data.result!.categoryWiseResults[props.focusCategory]
                      .percentage + '%'}
                  </Typography>
                </TableCell>
                <TableCell align='center'>
                  <Typography className='tableBodyText'>
                    {getLevel(
                      row.data.result!.categoryWiseResults[props.focusCategory]
                        .percentage
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
}

export default withRouter(CategoryBarChartEvent);
