import { Checkbox, Grid, Input, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { pipeline } from 'stream';
import { ALL } from '..';
import { Text } from '../../../../common/Language';
import { IServiceInfo, ITeamInfo } from '../../../../model';
import { IRootState } from '../../../../reducers';
import { Http } from '../../../../utils';
import { STATUS_CANCELED, STATUS_FAILED, STATUS_INPROGRESS, STATUS_PENDING, STATUS_SCHEDULED, STATUS_SKIPPED, STATUS_SUCCESS } from '../../../../model';
import { makeStyles } from 'tss-react/mui';

const PIPELINE_STATUS_LIST = [STATUS_CANCELED, STATUS_FAILED, STATUS_INPROGRESS, STATUS_PENDING, STATUS_SCHEDULED, STATUS_SKIPPED, STATUS_SUCCESS];

interface IProps extends RouteComponentProps{
    focusTeam: string[],
    focusService: string[],
    focusSubService: string[],
    focusServiceType: string[],
    focusPipelineStatus: string[],
    setFocusSubService: Function,
    setFocusService: Function,
    setFocusServiceType: Function,
    setFocusPipelineStatus: Function,
    setServiceListParent: Function,
    setFocusTeam: Function,
    isViewPipeline?: boolean
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
};

const useStyles = makeStyles()((theme) => ({
    containerTop: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      position: 'fixed',
      top: '100px',
      zIndex: 100,
      backgroundColor: '#ffffff'
    },
    container: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(3),
      position: 'relative',
      top: '120px',
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      paddingBottom: 0,
      border: '1px solid #b6b6b6',
    },
    doraMetricsContainer: {
      border: '1px solid #b1b1b1',
      marginTop: '20px',
      paddingTop: '10px',
      paddingBottom: '10px',
      borderRadius: '5px',
      boxShadow: '0px 0px 2px #a2a2a2',
      backgroundColor: '#e0e0e0',
    },
    fixedHeight: {
      height: 350,
    },
    toolbar: {
      fontSize: '12px !important',
      fontWeight: 'bold',
      display: 'initial',
    },
    viewMoreText: {
      color: 'blue',
      cursor: 'pointer',
      fontSize: '14px',
    },
    doraMetricsLinkText: {
      color: 'blue',
      cursor: 'pointer',
      float: 'right',
    },
}));



const renderSelectedTeams = (selected: any, objList: ITeamInfo[]) => {
    return (selected as string[]).map((val: string) => {
      const selObj: ITeamInfo | undefined = objList.find((t: ITeamInfo) => t.teamId === val);
      return selObj ? selObj.teamName : val;
    }).join(', ');
}

const renderSelectedServices = (selected: any, objList: IServiceInfo[]) => {
    return (selected as string[]).map((val: string) => {
      const selObj: IServiceInfo | undefined = objList.find((t: IServiceInfo) => t.id === val);
      return selObj ? selObj.name : val;
    }).join(', ');
}

const TeamAndServiceSelection = (props: IProps) => {
    const { classes } = useStyles();
    const stateVariable = useSelector((state: IRootState) => {
        return state;
    });
    const { focusTeam, focusService,
        focusSubService, setFocusTeam,
        focusServiceType, setFocusSubService,
        setFocusService, setFocusServiceType, setServiceListParent,
        focusPipelineStatus, setFocusPipelineStatus } = props;
    const [subServiceList, setSubServiceList] = useState<IServiceInfo[]>([]);
    const [serviceTypeList, setServiceTypeList] = useState<Object[]>([]);
    const [teamsFailureMsg, setTeamsListFailureMsg] = useState(false);
    const [teamList, setTeamList] = useState<ITeamInfo[]>([]);
    const [serviceList, setServiceList] = useState<IServiceInfo[]>([]);

    useEffect(() => {
        updateSubServiceList(serviceList);
        setFocusSubService([ALL]);
    }, [focusService]);
    
    useEffect(() => {
        let tempServiceList = updateServiceList(teamList);
        updateSubServiceList(tempServiceList);
        setFocusService([ALL]);
        setFocusSubService([ALL]);
    }, [focusTeam]);

    const updateFocusTeam = (event: any) => {
        let selectedTeams: string[] = event.target.value;
    
        if (selectedTeams.length === 0) {
          selectedTeams.push(ALL);
        } else if (selectedTeams[0] === ALL && selectedTeams.length > 1) {
          selectedTeams.shift();
        }
        setFocusTeam(selectedTeams);
    };

    const updateSubServiceList = (tempServiceList: any[]): any[] => {
        let tempSubServiceList: any[] = [];
        tempServiceList.forEach((service: any) => {
          if ((service.services) && ((focusService[0] === ALL) || (focusService.indexOf(service.id) > -1))) {
            tempSubServiceList = tempSubServiceList.concat(service.services);
          }
        });
        setSubServiceList(tempSubServiceList);
    
        return tempSubServiceList;
    };

    const updateFocusService = (event: any) => {
        let selectedServices: string[] = event.target.value;

        if (selectedServices.length === 0) {
        selectedServices.push(ALL);
        } else if (selectedServices[0] === ALL && selectedServices.length > 1) {
        selectedServices.shift();
        }
        setFocusService(selectedServices);
    };

    const updateFocusSubService = (event: any) => {
        let selectedSubServices: string[] = event.target.value;

        if (selectedSubServices.length === 0) {
        selectedSubServices.push(ALL);
        } else if (selectedSubServices[0] === ALL && selectedSubServices.length > 1) {
        selectedSubServices.shift();
        }
        setFocusSubService(selectedSubServices);
    };

    const updateFocusPipelineStatus = (event: any) => {
      let selectedPipelineStatus: string[] = event.target.value;
      if (selectedPipelineStatus.length === 0) {
        selectedPipelineStatus.push(ALL);
      } else if (selectedPipelineStatus[0] === ALL && selectedPipelineStatus.length > 1) {
        selectedPipelineStatus.shift();
      }
      setFocusPipelineStatus(selectedPipelineStatus);
  };

    const updateFocusServiceType = (event: any) => {
        let selectedServiceType: string[] = event.target.value;
        if (selectedServiceType.length === 0) {
        selectedServiceType.push(ALL);
        } else if (selectedServiceType[0] === ALL && selectedServiceType.length > 1) {
        selectedServiceType.shift();
        }
        setFocusServiceType(selectedServiceType);
    };

    const updateServiceList = (tempTeamList: any[]): any[] => {
        let tempServiceList: any[] = [];
        tempTeamList.forEach((team: any) => {
          if ((team.services) && ((focusTeam[0] === ALL) || (focusTeam.indexOf(team.teamId) > -1))) {
            tempServiceList = tempServiceList.concat(team.services);
          }
        });
        setServiceList(tempServiceList);
        setServiceListParent(tempServiceList);
        return tempServiceList;
    };

    useEffect(() => {
        getTeams();
    }, []);

    const getTeams = () => {
        Http.get({
          url: `/api/v2/teamlist`,
          state: stateVariable,
        })
          .then((response: any) => {
            const teamListCopy = [...response].filter((a: any) => {
              return a.active === 'true';
            });
            setTeamList(
              teamListCopy.sort((a: any, b: any) => {
                return a.teamName.localeCompare(b.teamName);
              })
            );
            let serviceListCopy = updateServiceList(teamListCopy);
            updateSubServiceList(serviceListCopy);
          })
          .catch((error: any) => {
            const perror = JSON.stringify(error);
            const object = JSON.parse(perror);
            if (object.code === 401) {
              props.history.push('/relogin');
            } else {
              setTeamsListFailureMsg(true);
            }
          });
    };

    return (
        <Grid container spacing={2} className={classes.containerTop}>
            <Grid item xs={12} md={2} lg={2}>
                <InputLabel id='team-select-label' style={{ color: '#525252', fontSize: '14px' }}>
                    <Text tid='team' />: &nbsp;
                    <Select labelId='team-multi-checkbox-label' id='team-multi-checkbox' multiple value={focusTeam}
                        onChange={updateFocusTeam} input={<Input />}
                        renderValue={(selected) => renderSelectedTeams(selected, teamList)}
                        MenuProps={MenuProps}
                        style={{ width: '70%' }}
                    >
                        {teamList.map((opt: any, i: number) => {
                            return (
                                <MenuItem key={i} value={opt.teamId}>
                                    <Checkbox checked={focusTeam.indexOf(opt.teamId)> -1}/>
                                    <ListItemText primary={opt.teamName} />
                                </MenuItem>
                            );
                        })}
                    </Select>
                    {teamsFailureMsg && (
                        <span style={{ color: '#f44336' }}>
                            <Text tid='errorInLoadingTeamList' />
                        </span>
                    )}
                </InputLabel>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
                <InputLabel id='service-select-label' style={{ color: '#525252', fontSize: '14px' }}>
                    <Text tid='serviceComponents' />: &nbsp;
                    <Select labelId='service-multi-checkbox-label' id='service-multi-checkbox' multiple
                        value={focusService} onChange={updateFocusService} input={<Input />}
                        renderValue={(selected) => renderSelectedServices(selected, serviceList)}
                        MenuProps={MenuProps}
                        style={{ width: '60%' }}
                    >
                        {serviceList.map((opt: any, i: number) => {
                            return (
                                <MenuItem key={i} value={opt.id}>
                                    <Checkbox checked={focusService.indexOf(opt.id)> -1}/>
                                    <ListItemText primary={opt.name} />
                                </MenuItem>
                            );
                        })}
                    </Select>
                </InputLabel>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
                <InputLabel id='subservice-select-label' style={{ color: '#525252', fontSize: '14px' }}>
                    <Text tid='serviceSubComponents' />: &nbsp;
                    <Select labelId='subservice-multi-checkbox-label' id='subservice-multi-checkbox' multiple
                        value={focusSubService} onChange={updateFocusSubService} input={<Input />}
                        renderValue={(selected) => renderSelectedServices(selected, subServiceList)}
                        MenuProps={MenuProps}
                        style={{ width: '55%' }}
                    >
                        {subServiceList.map((opt: any, i: number) => {
                            return (
                                <MenuItem key={i} value={opt.id}>
                                    <Checkbox checked={focusSubService.indexOf(opt.id)> -1}/>
                                    <ListItemText primary={opt.name} />
                                </MenuItem>
                            );
                        })}
                    </Select>
                </InputLabel>
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              {props.isViewPipeline ?
                <InputLabel id='pipelinestatus-select-label' style={{ color: '#525252', fontSize: '14px' }}>
                    Status: &nbsp;
                    <Select labelId='pipelinestatus-multi-checkbox-label' id='pipelinestatus-multi-checkbox' multiple
                        value={focusPipelineStatus} onChange={updateFocusPipelineStatus} input={<Input />}
                        renderValue={(selected) => (selected as string[]).join(', ')}
                        MenuProps={MenuProps}
                        style={{ width: '50%' }}
                    >
                        {PIPELINE_STATUS_LIST.map((opt: string) => {
                            return (
                                <MenuItem key={opt} value={opt}>
                                    <Checkbox checked={focusPipelineStatus.indexOf(opt)> -1}/>
                                    <ListItemText primary={opt} />
                                </MenuItem>
                            );
                        })}
                    </Select>
                </InputLabel>:
                <InputLabel id='servicetype-select-label' style={{ color: '#525252', fontSize: '14px' }}>
                    Service Type: &nbsp;
                    <Select labelId='servicetype-multi-checkbox-label' id='servicetype-multi-checkbox' multiple
                        value={focusServiceType} onChange={updateFocusServiceType} input={<Input />}
                        renderValue={(selected) => (selected as string[]).join(', ')}
                        MenuProps={MenuProps}
                        style={{ width: '50%' }}
                    >
                        {serviceTypeList.map((opt: any, i: number) => {
                            return (
                                <MenuItem key={i} value={opt.id}>
                                    <Checkbox checked={focusServiceType.indexOf(opt.id)> -1}/>
                                    <ListItemText primary={opt.name} />
                                </MenuItem>
                            );
                        })}
                    </Select>
                </InputLabel>
              }
            </Grid>
        </Grid>
    )
}

export default withRouter(TeamAndServiceSelection);


