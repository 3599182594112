import React, { Fragment } from 'react';
import TimelineButton from '../../../common/metrics/timelineButton';
import TimelineCalendar from '../../../common/metrics/timelineCalendar';
import { TimelineTooltip } from '../../../common/metrics/timelineTooltip';

export default function IncidentTimeline(props: any) {
  const { incidentTimeline, updateIncidentData, getIncidentCustomDates } = props;
  return (
    <Fragment>
      &nbsp; &nbsp;
      <TimelineButton
        timeline={incidentTimeline}
        updateData={(incidentTimeline: any) => updateIncidentData(incidentTimeline)}
      />
      &nbsp; &nbsp;
      <TimelineCalendar getCustomDates={getIncidentCustomDates} />
      &nbsp;
      {TimelineTooltip}
    </Fragment>
  );
}
