import React, { Fragment } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import { Text } from '../../common/Language';
import { makeStyles } from 'tss-react/mui';

interface ILoaderProps {
  label?: string;
}
const useStyles = makeStyles()((theme) => ({
  progress: {
    margin: theme.spacing(3),
  },
}));
const Loader = (props: ILoaderProps) => {
  const { classes } = useStyles();
  const LABEL = props.label ? props.label : <Text tid='loading' />;
  return (
    <Fragment>
      <CircularProgress className={classes.progress} />
      <Typography variant='h5'>{LABEL}</Typography>
    </Fragment>
  );
};
export default Loader;
