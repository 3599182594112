// const env = process.env.REACT_APP_STAGE;
import { createTheme } from '@mui/material';

export const buttonStyle = {
  color: '#042E5B',
  borderRadius: '2px',
  boxShadow: '0px 0px',
  borderWidth: '1px',
  borderColor: '#042E5B',
  '&:hover, &:focus, &:active': {
    color: '#FFFFFF',
    backgroundColor: '#042E5B',
    borderRadius: '2px',
    boxShadow: '0px 0px',
    borderWidth: '1px',
  },
};

/* export const timelineList = [
  { id: TIME_ONE_DAY, name: '1D' },
  { id: TIME_ONE_WEEK, name: '1W' },
  { id: TIME_ONE_MONTH, name: '1M' },
  { id: TIME_SIX_MONTHS, name: '6M' },
  { id: TIME_ONE_YEAR, name: '1Y' },
  { id: TIME_YTD, name: 'YTD' },
  { id: 'all', name: 'ALL' },
]; */

export const TIME_ONE_DAY = 'one_day';
export const TIME_ONE_WEEK = 'one_week';
export const TIME_TWO_WEEKS = 'two_weeks';
export const TIME_THREE_WEEKS = 'three_weeks';
export const TIME_ONE_MONTH = 'one_month';
export const TIME_SIX_MONTHS = 'six_months';
export const TIME_ONE_YEAR = 'one_year';
export const TIME_YTD = 'ytd';

export const timelineList = [
  { id: TIME_ONE_DAY, name: '1D' },
  { id: TIME_ONE_WEEK, name: '1W' },
  { id: TIME_TWO_WEEKS, name: '2W' },
  { id: TIME_THREE_WEEKS, name: '3W' },
  { id: TIME_ONE_MONTH, name: '1M' },
];

export const tooltipTheme = createTheme({}, {
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: '#ffffff',
        backgroundColor: '#000000',
        width: '15vw',
      },
    },
  },
});
